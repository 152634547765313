import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../assets/logo_png_zucco.png'
import { useEffect, useState } from 'react';
import { ModalProposta } from './ModalProposta';
import { isMobile } from '../shared/utils/javascript';

export const Header = () => {
    const navigate = useNavigate()
    const [dropdownOpen, setDropdownOpen] = useState({seguro: false, consorcio: false, mobile: false});

    useEffect(() => {
        const handleMouseLeave = () => {
            setDropdownOpen({seguro: false, consorcio: false, mobile: false});
        };
    
        document.addEventListener('mouseleave', handleMouseLeave);
    
        return () => {
          document.removeEventListener('mouseleave', handleMouseLeave);
        };
      }, []);

    const handleHoverEnter = (x: number) => {
        if(x === 0) {
            setDropdownOpen({seguro: true, consorcio: dropdownOpen.consorcio, mobile: false});
        } else {
            setDropdownOpen({seguro: dropdownOpen.seguro, consorcio: true, mobile: false});
        }
    };

    const handleHoverLeave = (x: number) => {
        if(x === 0) {
            setDropdownOpen({seguro: false, consorcio: dropdownOpen.consorcio, mobile: false});
        } else {
            setDropdownOpen({seguro: dropdownOpen.seguro, consorcio: false, mobile: false});
        }
    };

    const handleNavigateMobile = (link: string) => {
        setDropdownOpen({seguro: false, consorcio: false, mobile: false});
        navigate(link);
    }

    const getTitle = () => {
        const path = window.location.pathname
        switch (path) {
            case "/":
                return "Início"
            case "/sobre-nos":
                return "Sobre nós"
            case "/seguro-auto":
                return "Seguro Auto"
            case "/seguro-residencial":
                return "Seguro Resid."
            case "/seguro-vida":
                return "Seguro de Vida"
            case "/seguro-empresa":
                return "Seguro de Emp."
            case "/asseguradoras":
                return "Seguradoras"
            case "/consorcio-auto":
                return "Consórcio Auto"
            case "/consorcio-imobiliario":
                return "Consórcio Imo."
            default:
                return "Início"
          }
    }

    return (
        <div className='flex justify-between mobile:items-center bg-white sticky top-0 z-50'>
            <ModalProposta />
            <div className='px-10 py-3 mobile:px-4 mobile:py-3'>
                <img className='cursor-pointer max-w-20' src={Logo} alt="Zucco" onClick={() => navigate("/")} />
            </div>
            {isMobile() ? (
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn bg-transparent mx-1 border-none font-medium text-base shadow-none" onClick={() => setDropdownOpen({seguro: false, consorcio: false, mobile: true})}>{getTitle()} <i className="ri-menu-line ri-lg"></i></div>
                    <ul tabIndex={0} className={`dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 ${dropdownOpen.mobile ? "" : "hidden"}`}>
                        <li onClick={() => handleNavigateMobile("/")} className='border-b border-accent'><a>Início</a></li>
                        <li onClick={() => handleNavigateMobile("/sobre-nos")} className='border-b border-accent'><a>Sobre nós</a></li>
                        <li onClick={() => handleNavigateMobile("/seguro-auto")}><a>Seguro Auto</a></li>
                        <li onClick={() => handleNavigateMobile("/seguro-residencial")}><a>Seguro Residencial</a></li>
                        <li onClick={() => handleNavigateMobile("/seguro-vida")}><a>Seguro de Vida</a></li>
                        <li onClick={() => handleNavigateMobile("/seguro-empresa")} className='border-b border-accent'><a>Seguro Empresa</a></li>
                        <li onClick={() => handleNavigateMobile("/asseguradoras")} className='border-b border-accent'><a>Seguradoras</a></li>
                        <li onClick={() => handleNavigateMobile("/consorcio-auto")}><a>Consórcio Auto</a></li>
                        <li onClick={() => handleNavigateMobile("/consorcio-imobiliario")}><a>Consórcio Imobiliário</a></li>
                    </ul>
                </div>
            ) : (
                <>
                    <div>
                        <div onClick={() => navigate("/")} tabIndex={0} role="button" className="btn btn-secondary text-base outline-none shadow-none font-medium rounded-none h-full px-7 py-5 hover:bg-primary hover:text-white border-none">Início</div>
                        <div onClick={() => navigate("/sobre-nos")} tabIndex={0} role="button" className="btn btn-secondary text-base outline-none shadow-none font-medium rounded-none h-full px-7 py-5 hover:bg-primary hover:text-white border-none">Sobre nós</div>
                        <div 
                            className="dropdown dropdown-hover h-full"
                            onMouseEnter={() => handleHoverEnter(0)}
                            onMouseLeave={() => handleHoverLeave(0)}
                        >
                            <div tabIndex={0} role="button" className={`btn btn-secondary text-base outline-none shadow-none font-medium rounded-none h-full px-7 py-5 hover:bg-primary hover:text-white border-none ${dropdownOpen.seguro ? "bg-primary text-white" : ""}`}>Seguros</div>
                            <ul onClick={() => setDropdownOpen({seguro: false, consorcio: false, mobile: false})} tabIndex={0} className={`dropdown-content z-[1] menu p-2 shadow bg-primary rounded-none w-52 text-white ${dropdownOpen.seguro ? "" : "hidden"}`}>
                                <li onClick={() => navigate("/seguro-auto")}><a>Seguro Auto</a></li>
                                <li onClick={() => navigate("/seguro-residencial")}><a>Seguro Residencial</a></li>
                                <li onClick={() => navigate("/seguro-vida")}><a>Seguro de Vida</a></li>
                                <li onClick={() => navigate("/seguro-empresa")}><a>Seguro Empresa</a></li>
                            </ul>
                        </div>
                        <div onClick={() => navigate("/asseguradoras")} tabIndex={0} role="button" className="btn btn-secondary text-base outline-none shadow-none font-medium rounded-none h-full px-7 py-5 hover:bg-primary hover:text-white border-none">Seguradoras</div>
                        <div 
                            className="dropdown dropdown-hover h-full"
                            onMouseEnter={() => handleHoverEnter(1)}
                            onMouseLeave={() => handleHoverLeave(1)}
                        >
                            <div tabIndex={0} role="button" className={`btn btn-secondary text-base outline-none shadow-none font-medium rounded-none h-full px-7 py-5 hover:bg-primary hover:text-white border-none ${dropdownOpen.consorcio ? "bg-primary text-white" : ""}`}>Consórcio</div>
                            <ul onClick={() => setDropdownOpen({seguro: false, consorcio: false, mobile: false})} tabIndex={0} className={`dropdown-content z-[1] menu p-2 shadow bg-primary rounded-none w-52 text-white ${dropdownOpen.consorcio ? "" : "hidden"}`}>
                                <li onClick={() => navigate("/consorcio-auto")}><a>Consórcio Auto</a></li>
                                <li onClick={() => navigate("/consorcio-imobiliario")}><a>Consórcio Imobiliário</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='px-10 py-3 self-center'>
                        <button 
                            onClick={() => {
                                const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }}
                            className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight"
                        >
                            <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                            Cotar seguro
                        </button>
                    </div>
            </>
            )}
        </div>
    )
}