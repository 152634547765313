import { useEffect, useState } from "react";

import bgCobertura from '../assets/bgHomeCardSeguros.png'
import hospital from '../assets/hospital.png'
import doencas from '../assets/doencas.png'
import medicos from '../assets/medicos.png'
import nutricional from '../assets/nutricional.png'
import fatalidadeCobertura from '../assets/fatalidadeCobertura.png'
import invalidez from '../assets/invalidez.png'
import funcional from '../assets/funcional.png'
import laborativa from '../assets/laborativa.png'

import { CardCobertura } from "../components/CardCobertura";
import { CardClientes } from "../components/CardClientes";
import { Propaganda } from "../components/Propaganda";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { listaClientes } from "../shared/utils/contantes";
import { postEmail } from "../service/api";

const SeguroVida: React.FC = () => {

    const lista = [
        {
            img: laborativa,
            titulo: "Internação Hospitalar",
            texto: "No seguro de vida oferece indenização ao segurado ou beneficiário em caso de hospitalização por doença ou acidente."
        },
        {
            img: funcional,
            titulo: "Doenças Graves",
            texto: "Indenização ao segurado diagnosticado com condições pré-determinadas, proporcionando suporte financeiro."
        },
        {
            img: invalidez,
            titulo: "Despesas médicas",
            texto: "Oferece reembolso ou pagamento direto das despesas médicas e hospitalares do segurado."
        },
        {
            img: fatalidadeCobertura,
            titulo: "Assistência nutricional",
            texto: "Suporte profissional para orientação e cuidados nutricionais, promovendo a saúde e o bem-estar do segurado."
        },
        {
            img: nutricional,
            titulo: "Cobertura por Fatalidade",
            texto: "Beneficiários designados em caso de falecimento do segurado durante a vigência da apólice."
        },
        {
            img: medicos,
            titulo: "Invalidez Permanente",
            texto: "Indenização ao segurado em caso de lesão ou invalidez permanente por acidente, com suporte financeiro."
        },
        {
            img: doencas,
            titulo: "Invalidez Funcional",
            texto: "Indenização caso o segurado perca a capacidade de realizar atividades diárias essenciais."
        },
        {
            img: hospital,
            titulo: "Invalidez Laborativa",
            texto: "Perde a capacidade de exercer sua profissão habitual devido a um evento coberto pela apólice."
        },
    ]
    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        dataNascimento: "",
        profissao: "",
    })
    const [accordionState, setAccordionState] = useState(1);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
 
    return (
        <div>
            <div className="bg-bgDivSeguroVida h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivSeguroVidaMobile bg-cover">
            {isMobile() ? null : (
                <div className="modal-box rounded-lg p-5 flex items-center flex-col bg-white">
                    <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                    <div className="w-full">
                    <div className="flex items-center w-full mt-8">
                        <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                        <hr className="w-full h-[2px] bg-primary" />
                        <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                    </div>
                    {etapa === 0 ? (
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full bg-white" />
                            <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                        </div>
                    ) : (
                        <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.profissao} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    profissao: event.target.value
                                  }));
                            }} type="text" placeholder="Profissão" className="input input-bordered w-full bg-white" />
                        <div className="w-full mt-5 grid grid-cols-2 gap-5">
                            <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                            <button onClick={() => {
                                postEmail({
                                    to: "vendas1@zuccoseguros.com.br",
                                    replyTo: objEmail.email,
                                    subject: "Contato para Zucco",
                                    html: {
                                        nome: objEmail.nome,
                                        tipo: "Seguro de Vida",
                                        cpf: objEmail.cpf,
                                        telefone: objEmail.telefone,
                                        email: objEmail.email,
                                        profissao: objEmail.profissao,
                                        dataNascimento: objEmail.dataNascimento,
                                    },
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                  })

                                setObjEmail({
                                    tipo: "",
                                    nome: "",
                                    cpf: "",
                                    email: "",
                                    telefone: "",
                                    dataNascimento: "",
                                    profissao: "",
                                })
                                setEtapa(0)
                            }} className="btn btn-primary text-white">Enviar Proposta</button>
                        </div>
                    </div>
                    )}
                    </div>
                </div>
            )}
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14">Aqui alguma das coberturas para <span className="text-primary">você e sua família</span></h3>
            <img src={bgCobertura} alt="Nossa história" className="mobile:h-60 mobile:object-cover" />
            <CardCobertura lista={lista} />
            
            <h3 className="font-semibold text-2xl flex gap-2 px-48 leading-[16px] mt-24 mobile:px-3 mobile:mt-14 mobile:text-base">Saiba o que <span className="text-primary">nossos clientes falam</span></h3>
            <CardClientes lista={listaClientes} />

            <Propaganda />

            <div className="px-48 mt-24 mb-40 mobile:px-3 mobile:mb-20">
                <h2 className="text-2xl font-semibold mobile:text-base">Dúvidas sobre o <span className="text-primary">Seguro de Vida?</span></h2>
                <div className="flex flex-col gap-1 mt-3 mobile:mt-0">
                    <div onClick={() => setAccordionState(1)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 1}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>O que são coberturas de proteção familiar?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col mobile:text-xs"> 
                            <p className="text-justify font-medium leading-tight">
                            As coberturas de proteção familiar são recursos oferecidos por seguradoras para resguardar a família em situações de imprevistos financeiros ou eventos inesperados. Essas coberturas podem incluir seguro de vida, que oferece indenização em caso de falecimento do segurado, auxiliando a família financeiramente. Além disso, podem envolver coberturas por invalidez permanente, que garantem suporte em casos de incapacidade para o trabalho, e assistências diversas, como serviços funerários e apoio psicológico. Essas coberturas visam proporcionar segurança financeira e suporte emocional para a família em momentos difíceis.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(2)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 2}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Por que contratar um seguro de vida?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col mobile:text-xs"> 
                            <p className="text-justify font-medium leading-tight">
                            Contratar um seguro de vida oferece uma série de benefícios. Ele proporciona segurança financeira para sua família em caso de sua ausência, garantindo que eles não fiquem desamparados. Pode cobrir despesas médicas e funeral, quitar dívidas, manter o padrão de vida dos beneficiários e até garantir a continuidade dos estudos dos filhos. Além disso, em alguns casos, o seguro de vida pode ser uma forma de investimento, oferecendo opções de resgate ou renda complementar no futuro. É uma maneira de planejar o futuro e proteger aqueles que você ama.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(3)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 3}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Qual é o melhor plano do seguro de vida?</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">O melhor plano de seguro de vida é aquele que se encaixa no bolso da pessoa, adaptado às suas necessidades e possibilidades financeiras. É crucial considerar o equilíbrio entre as coberturas oferecidas e o valor do prêmio, garantindo que a proteção financeira oferecida seja acessível e proporcione tranquilidade. Avaliar as coberturas essenciais, como assistência funeral, invalidez, doenças graves e a indenização aos beneficiários em caso de falecimento, é fundamental. Um plano que ofereça segurança e caiba no orçamento é o mais adequado, assegurando proteção sem comprometer a estabilidade financeira atual.</p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                </div>
            </div>
        </div>
    )
}

export default SeguroVida;