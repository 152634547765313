import { useEffect, useRef, useState } from "react";

import bgCobertura from '../assets/bgHomeCardSeguros.png'
import planejamento from '../assets/planejamento.png'
import semEntrada from '../assets/semEntrada.png'
import patrimonio from '../assets/patrimonio.png'
import menorRisco from '../assets/menorRisco.png'

import { CardCobertura } from "../components/CardCobertura";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { postEmail } from "../service/api";

export const ConsorcioAuto = () => {

    const lista = [
        {
            titulo: "Planejamento é a palavra",
            texto: "Sem juros, parcelas acessíveis e contemplação por sorteio ou lance, permitindo a conquista planejada.",
            img: planejamento
        },
        {
            titulo: "Sem entrada",
            texto: "Oportunidade de adquirir bens com parcelas acessíveis e possibilidade de contemplação por sorteio ou lance.",
            img: semEntrada
        },
        {
            titulo: "Construção de Patrimônio",
            texto: "É possível construir patrimônio de forma planejada, investindo em bens sem pagar juros.",
            img: patrimonio
        },
        {
            titulo: "Menor risco",
            texto: "Menor risco financeiro, pois não há cobrança de juros, permitindo a aquisição planejada de bens.",
            img: menorRisco
        },
    ]
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        valor: "",
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
          const scrollContainer = scrollContainerRef.current;
    
          // Largura total do conteúdo
          const contentWidth = scrollContainer.scrollWidth;
    
          // Largura visível do contêiner
          const containerWidth = scrollContainer.clientWidth;
    
          // Defina a posição inicial do scroll para o meio
          scrollContainer.scrollLeft = (contentWidth - containerWidth) / 2;
        }
      }, []);

      useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div>
            <div className="bg-bgDivConsorcioAuto h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivConsorcioAutoMobile mobile:bg-cover">
                {isMobile() ? null : (
            <div className="modal-box rounded-lg p-5 flex items-center flex-col">
                <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                <div className="w-full mt-2">
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full" />
                            <input value={objEmail.valor} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    valor: event.target.value
                                  }));
                            }} type="text" placeholder="Valor da carta de consórcio" className="input input-bordered w-full" />
                            <button onClick={() => {
                                    postEmail({
                                        to: "vendas1@zuccoseguros.com.br",
                                        replyTo: objEmail.email,
                                        subject: "Contato para Zucco",
                                        html: {
                                            nome: objEmail.nome,
                                            tipo: "Consórcio Auto",
                                            cpf: objEmail.cpf,
                                            telefone: objEmail.telefone,
                                            email: objEmail.email,
                                            valor: objEmail.valor,
                                        },
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                      })

                                    setObjEmail({
                                        tipo: "",
                                        nome: "",
                                        cpf: "",
                                        email: "",
                                        telefone: "",
                                        valor: "",
                                    })

                                        const modal = document.getElementById('modal_consorcio') as HTMLDialogElement;;
                
                                        if (modal) {
                                          modal.close();
                                        } else {
                                          console.error("Element with ID 'modal_proposta' not found.");
                                        }
                                }} className="btn btn-primary text-white">Enviar proposta</button>
                        </div>                    
                </div>
            </div>
            )}
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14">Algumas vantagens de <span className="text-primary">fazer um consórcio</span></h3>
            <img src={bgCobertura} alt="Nossa história" className="mobile:h-60 mobile:object-cover" />
            <CardCobertura lista={lista} />

            <div className="bg-consorcioAuto h-[560px] mt-32 mobile:bg-bgConsorcioInfosMobile bg-cover mobile:mt-20" />
            <div className="px-48 mt-[-510px] flex flex-col gap-5 mobile:px-0">
                <div className="mobile:px-3">
                    <div className="collapse collapse-arrow bg-white max-w-[540px] p-2">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <i className="ri-git-repository-line"></i>
                            <h4>Por que Consórcio com a Zucco Seguros?</h4>
                        </div>
                        <div className="collapse-content mobile:text-xs"> 
                            <p className="text-justify font-medium leading-tight">Nossa Corretora, opera com duas grandes administradoras de Consórcio do Brasil, empresas idôneas que assim como a Zucco cumprem seus compromissos, honra seus comprometimentos e é íntegra em suas ações. 
                                Nossas parceiras RODOBENS e PORTO BANK que juntas somam mais de 140 anos de experiência, no mercado, adquirindo conhecimento e transformando em realidade o objetivo de empreender e evoluir, sempre respeitando a trajetória de cada cliente.
                                A Zucco acredita que histórias são feitas de pequenas e grandes conquistas, e estar presente em cada uma delas, é o passo principal para chegar onde desejamos, seja a aquisição de um carro ou a casa dos seus sonhos. Você planeja e nós somos o parceiro para te ajudar a realizar.</p>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="grid grid-cols-3 gap-24 mt-8 mobile:overflow-x-auto mobile:flex mobile:gap-2 mobile:mt-5 mobile:pb-3" ref={scrollContainerRef}>
                        <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                            <h1 className="font-slab text-5xl mobile:text-3xl">+20 Anos</h1>
                            <h4 className="leading-tight font-medium text-center text-xl" style={{fontVariant: "small-caps"}}>anos de experiência</h4>
                        </div>
                        <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                            <h1 className="font-slab text-5xl mobile:text-3xl">+3.000 pessoas</h1>
                            <h4 className="leading-tight font-medium text-center text-xl" style={{fontVariant: "small-caps"}}>Consorciados em nossos grupos ativos</h4>
                        </div>
                        <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                            <h1 className="font-slab text-5xl mobile:text-3xl">+10 parceiros</h1>
                            <h4 className="leading-tight font-medium text-center text-xl" style={{fontVariant: "small-caps"}}>Cartas de crédito contempladas</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}