import propagandaFamilia from '../assets/propagandaFamilia.png'
import propagandaAuto from '../assets/propagandaAuto.png'
import propagandaEmpresa from '../assets/propagandaEmpresa.png'
import propagandaResidencia from '../assets/propagandaResidencia.png'
import { useEffect, useState } from 'react'
import { isMobile } from '../shared/utils/javascript'
import { useNavigate } from 'react-router-dom'

export const Propaganda = () => {

    const [numero, setNumero] = useState(0)
    const navigate = useNavigate()

    function numeroAleatorio(): number {
        return Math.floor(Math.random() * 4);
      }

      useEffect(() => {
        setNumero(numeroAleatorio())
      },[])

      const retornoPropaganda = () => {
        switch (numero) {
            case 0:
              return (
                <div className='flex flex-row shadow-shadow rounded-r-lg mobile:flex-col' style={{background: "linear-gradient(180deg, #F3F3F3 0%, #FFF 100%)"}}>
                    {isMobile() ? null : (
                        <>
                            <div className='bg-primary w-32 h-auto rounded-l-lg' />
                            <img src={propagandaFamilia} alt="Proteja também sua casa ou apartamento" className='cursor-pointer' onClick={() => {navigate("/seguro-vida")}} />
                        </>
                    )}
                    <div className='flex gap-3 flex-col justify-center max-w-[545px]'>
                        <h1 className='text-[32px] font-semibold leading-tight mobile:px-3 mobile:text-base mobile:mt-5'>Nós sabemos que não tem nada mais precioso que <span className='text-primary'>nossa família!</span></h1>
                        <h2 className='text-justify text-xl font-medium leading-tight mobile:px-3 mobile:text-sm mobile:text-start'>Por isso também temos seguro de <span className='text-error'>vida para você e sua família</span> com as principais seguradoras do Brasil.</h2>
                        <div className="grid grid-cols-2 gap-2 mt-1 mobile:px-3">
                        <button 
                            onClick={() => {
                                const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }}
                            className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-2"
                        >
                            <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                            Cotar seguro
                        </button>
                        <button
                            onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                            className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-1"
                        >
                            <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                            Whatsapp
                        </button>
                    </div>
                    {!isMobile() ? null : (
                        <div className='mt-5 overflow-hidden'>
                            <div className='bg-primary w-16 h-auto rounded-bl-lg' />
                            <img src={propagandaFamilia} alt="Proteja também sua casa ou apartamento" onClick={() => {navigate("/seguro-vida")}} className='mobile:object-cover cursor-pointer max-w-none overflow-hidden h-56' />
                        </div>
                    )}
                    </div>
                </div>
              )
            case 1:
                return (
                    <div className='flex flex-row shadow-shadow rounded-r-lg mobile:flex-col' style={{background: "linear-gradient(180deg, #F3F3F3 0%, #FFF 100%)"}}>
                        {isMobile() ? null : (
                            <>
                                <div className='bg-primary w-32 h-auto rounded-l-lg' />
                                <img src={propagandaResidencia} alt="Proteja também sua casa ou apartamento" className='cursor-pointer' onClick={() => {navigate("/seguro-residencial")}} />
                            </>
                        )}
                        <div className='flex gap-3 flex-col justify-center max-w-[545px]'>
                            <h1 className='text-[32px] font-semibold leading-tight mobile:px-3 mobile:text-base mobile:mt-5'>Proteja também sua casa ou apartamento</h1>
                            <h2 className='text-justify text-xl font-medium leading-tight mobile:px-3 mobile:text-sm mobile:text-start'>Transforme seu espaço em um refúgio seguro e acolhedor com medidas de proteção adequadas.</h2>
                            <div className="grid grid-cols-2 gap-2 mt-1 mobile:px-3">
                            <button 
                                onClick={() => {
                                    const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
    
                                    if (modal) {
                                    modal.showModal();
                                    } else {
                                    console.error("Element with ID 'modal_proposta' not found.");
                                    }
                                }}
                                className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-2"
                            >
                                <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                                Cotar seguro
                            </button>
                            <button
                                onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                                className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-1"
                            >
                                <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                                Whatsapp
                            </button>
                        </div>
                        {!isMobile() ? null : (
                            <div className='mt-5 overflow-hidden'>
                                <div className='bg-primary w-16 h-auto rounded-bl-lg' />
                                <img src={propagandaResidencia} alt="Proteja também sua casa ou apartamento" onClick={() => {navigate("/seguro-residencial")}} className='mobile:object-cover max-w-none overflow-hidden h-56 cursor-pointer' />
                            </div>
                        )}
                        </div>
                    </div>
                  )
            case 2:
                return (
                    <div className='flex flex-row shadow-shadow rounded-r-lg mobile:flex-col' style={{background: "linear-gradient(180deg, #F3F3F3 0%, #FFF 100%)"}}>
                        {isMobile() ? null : (
                            <>
                                <div className='bg-primary w-32 h-auto rounded-l-lg' />
                                <img src={propagandaEmpresa} alt="Proteja também sua casa ou apartamento" className='cursor-pointer' onClick={() => {navigate("/seguro-empresa")}} />
                            </>
                        )}
                        <div className='flex gap-3 flex-col justify-center max-w-[545px] ml-11 mobile:ml-0'>
                            <h1 className='text-[32px] font-semibold leading-tight mobile:px-3 mobile:text-base mobile:mt-5'>A sua empresa também merece uma proteção.</h1>
                            <h2 className='text-justify text-xl font-medium leading-tight mobile:px-3 mobile:text-sm mobile:text-start'>Proteja o futuro e o sucesso da sua empresa com soluções de segurança confiáveis e adaptadas ao seu negócio.</h2>
                            <div className="grid grid-cols-2 gap-2 mt-1 mobile:px-3">
                            <button 
                                onClick={() => {
                                    const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
    
                                    if (modal) {
                                    modal.showModal();
                                    } else {
                                    console.error("Element with ID 'modal_proposta' not found.");
                                    }
                                }}
                                className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-2"
                            >
                                <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                                Cotar seguro
                            </button>
                            <button
                                onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                                className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-1"
                            >
                                <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                                Whatsapp
                            </button>
                        </div>
                        {!isMobile() ? null : (
                            <div className='mt-5 overflow-hidden'>
                                <div className='bg-primary w-16 h-auto rounded-bl-lg' />
                                <img src={propagandaEmpresa} alt="Proteja também sua casa ou apartamento" className='mobile:object-cover max-w-none overflow-hidden h-56 cursor-pointer' onClick={() => {navigate("/seguro-empresa")}} />
                            </div>
                        )}
                        </div>
                    </div>
                  )
            case 3:
                return (
                    <div className='flex flex-row shadow-shadow rounded-r-lg mobile:flex-col' style={{background: "linear-gradient(180deg, #F3F3F3 0%, #FFF 100%)"}}>
                        {isMobile() ? null : (
                            <>
                                <div className='bg-primary w-32 h-auto rounded-l-lg' />
                                <img src={propagandaAuto} alt="Proteja também sua casa ou apartamento" className='cursor-pointer' onClick={() => {navigate("/seguro-auto")}} />
                            </>
                        )}
                        <div className='flex gap-3 flex-col justify-center max-w-[545px] ml-11 mobile:ml-0'>
                            <h1 className='text-[32px] font-semibold leading-tight mobile:px-3 mobile:text-base mobile:mt-5'>Proteja seu automóvel com as melhores asseguradoras </h1>
                            <h2 className='text-justify text-xl font-medium leading-tight mobile:px-3 mobile:text-sm mobile:text-start'>Garanta tranquilidade e segurança para seu veículo com as opções mais confiáveis de seguradoras do mercado.</h2>
                            <div className="grid grid-cols-2 gap-2 mt-1 mobile:px-3">
                            <button 
                                onClick={() => {
                                    const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
    
                                    if (modal) {
                                    modal.showModal();
                                    } else {
                                    console.error("Element with ID 'modal_proposta' not found.");
                                    }
                                }}
                                className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-2"
                            >
                                <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                                Cotar seguro
                            </button>
                            <button
                                onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                                className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-1"
                            >
                                <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                                Whatsapp
                            </button>
                        </div>
                        {!isMobile() ? null : (
                            <div className='mt-5 overflow-hidden'>
                                <div className='bg-primary w-16 h-auto rounded-bl-lg' />
                                <img src={propagandaAuto} alt="Proteja também sua casa ou apartamento" className='mobile:object-cover max-w-none overflow-hidden h-56 cursor-pointer' onClick={() => {navigate("/seguro-auto")}} />
                            </div>
                        )}
                        </div>
                    </div>
                  )
            default:
                return (
                    <div className='flex flex-row shadow-shadow rounded-r-lg mobile:flex-col' style={{background: "linear-gradient(180deg, #F3F3F3 0%, #FFF 100%)"}}>
                        {isMobile() ? null : (
                            <>
                                <div className='bg-primary w-32 h-auto rounded-l-lg' />
                                <img src={propagandaAuto} alt="Proteja também sua casa ou apartamento" className='cursor-pointer' onClick={() => {navigate("/seguro-auto")}} />
                            </>
                        )}
                        <div className='flex gap-3 flex-col justify-center max-w-[545px] ml-11 mobile:ml-0'>
                            <h1 className='text-[32px] font-semibold leading-tight mobile:px-3 mobile:text-base mobile:mt-5'>Proteja seu automóvel com as melhores asseguradoras </h1>
                            <h2 className='text-justify text-xl font-medium leading-tight mobile:px-3 mobile:text-sm mobile:text-start'>Garanta tranquilidade e segurança para seu veículo com as opções mais confiáveis de seguradoras do mercado.</h2>
                            <div className="grid grid-cols-2 gap-2 mt-1 mobile:px-3">
                            <button 
                                onClick={() => {
                                    const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
    
                                    if (modal) {
                                    modal.showModal();
                                    } else {
                                    console.error("Element with ID 'modal_proposta' not found.");
                                    }
                                }}
                                className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-2"
                            >
                                <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                                Cotar seguro
                            </button>
                            <button
                                onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                                className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:order-1"
                            >
                                <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                                Whatsapp
                            </button>
                        </div>
                        {!isMobile() ? null : (
                            <div className='mt-5 overflow-hidden'>
                                <div className='bg-primary w-16 h-auto rounded-bl-lg' />
                                <img src={propagandaAuto} alt="Proteja também sua casa ou apartamento" className='mobile:object-cover max-w-none overflow-hidden h-56 cursor-pointer' onClick={() => {navigate("/seguro-auto")}} />
                            </div>
                        )}
                        </div>
                    </div>
                  )
          }
      }

    return (
        <div className="mt-24 px-48 max-h-[400px] mobile:mt-14 mobile:px-3">
            {retornoPropaganda()}
        </div>
    )
}