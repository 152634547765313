import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { WhatssApp } from "./components/WhatssApp";
import { postVisitas } from "./service/api";
import { isMobile } from "./shared/utils/javascript";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [ip, setIP] = useState()

  const getData = async () => {
    await axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIP(response.data.ip);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const salvarAcesso = async () => {
    await getData();
    if(!ip) return

    try {
      await postVisitas({
        ip: ip,
        dispositivo: isMobile() ? 'MOBILE' : 'PC',
        lojaId: Number(process.env.REACT_APP_COD_LOJA)
      });
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    salvarAcesso()
  },[ip])

  return (
    <div className="App">
        <BrowserRouter>
          <ToastContainer />
          {!window.location.pathname.includes('admin') ? (<WhatssApp />): null}
          {!window.location.pathname.includes('admin') ? (<Header />): null}
          <Routes />
          {!window.location.pathname.includes('admin') ? (<Footer />): null}
        </BrowserRouter>
    </div>
  );
}

export default App;
