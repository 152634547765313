//================================================
// Envios para o back end

//================================================
// Retornos do back end

export enum InfosLoja {
  NOME = "nome",
  LOJA = "loja",
}
export enum Acessos {
  DIA = "dia",
  MES = "mes",
  ANO = "ano",
  ACESSOS = "acessos",
}
export enum Whatsapp {
  DATA_HORA = "data_hora",
  DISPOSITIVO = "dispositivo",
  CLIQUES = "whatsappCliques",
}
export enum DescLoja {
  DESCRICAO = "descricao",
  INSTAGRAM = "urlInstagram",
  FACEBOOK = "urlFacebook",
  INFOS_LOJA = "infosLoja",
  TBL_INFOS_LOJA = "tbl_infosLoja"
}
export enum Banner {
  ID = "id",
  BANNER = "banner",
  URL = "url",
  TITULO = "titulo",
  SITUACAO = "situacao",
  POSICAO = "posicao",
  DATA_INICIO = "data_inicio",
  DATA_FIM = "data_fim",
  ARQUIVO = "arquivo",
  IS_MOBILE = "isMobile"
}

//================================================
// Helpers
export enum Contador {
  CONTADOR = "contador",
}
