import { useEffect, useState } from "react";
import { Propaganda } from "../components/Propaganda";

import bgAsseguradoras from '../assets/bgAsseguradoras.png'
import aliroBanner from '../assets/aliroBanner.png'
import allianzBanner from '../assets/allianzBanner.png'
import azulBanner from '../assets/azulBanner.png'
import bradescoBanner from '../assets/bradescoBanner.png'
import hdiBanner from '../assets/hdiBanner.png'
import liberyBanner from '../assets/liberyBanner.png'
import mapfreBanner from '../assets/mapfreBanner.png'
import portoBanner from '../assets/portoBanner.png'
import sompoBanner from '../assets/sompoBanner.png'
import suhaiBanner from '../assets/suhaiBanner.png'
import tokioBanner from '../assets/tokioBanner.png'
import zurichBanner from '../assets/zurichBanner.png'
import bgSobreNosMobilePagina from '../assets/bgSobreNosMobilePagina.jpg'

import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { postEmail } from "../service/api";

export const Asseguradoras = () => {

    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        dataNascimento: "",
        placaCarro: "",
        marca: "",
        modelo: "",
        cidade: "",
        profissao: "",
        cnpj: "",
        ramoEmpresa: ""
    })
    const lista = [
        {
            imagem: allianzBanner,
            texto: "A Allianz é uma das maiores e mais respeitadas seguradoras do mundo, reconhecida por sua solidez e inovação. Com uma presença global, oferece uma ampla gama de serviços e produtos, desde seguros automotivos e residenciais até seguros corporativos e de vida, destacando-se pela excelência em coberturas e atendimento ao cliente. Sua reputação é construída sobre um compromisso contínuo com a qualidade, segurança e confiabilidade."
        },
        {
            imagem: aliroBanner,
            texto: "A Aliro Seguros é uma empresa em ascensão no mercado, destacando-se por sua abordagem disruptiva e foco na experiência do cliente. Ao adotar uma visão inovadora, a Aliro simplifica o processo de obtenção de seguros, oferecendo soluções acessíveis e personalizadas para atender às necessidades individuais dos clientes. Sua plataforma tecnológica avançada permite uma experiência simplificada."
        },
        {
            imagem: azulBanner,
            texto: "A Azul Seguros é reconhecida pela sua abordagem moderna e personalizada no mercado de seguros no Brasil. Destaca-se por oferecer soluções acessíveis e completas, desde seguros automotivos até residenciais e empresariais, proporcionando tranquilidade aos clientes por meio de coberturas abrangentes e um atendimento eficiente. Sua reputação é respaldada pela constante busca por inovação."
        },
        {
            imagem: bradescoBanner,
            texto: "A Bradesco Seguros é uma das maiores seguradoras do país, conhecida por sua solidez e ampla gama de serviços. Oferece desde seguros de vida e saúde até automóveis e residenciais, priorizando a excelência no atendimento e na oferta de soluções completas para seus segurados. Sua reputação é construída sobre valores como confiança, inovação e compromisso com a segurança financeira e o bem-estar dos clientes."
        },
        {
            imagem: hdiBanner,
            texto: "HDI Seguros é reconhecida pela sua expertise e foco na excelência em serviços de seguros. Destaca-se por oferecer soluções abrangentes, vários seguros, com um atendimento personalizado e eficiente. Sua reputação é respaldada por sua dedicação à inovação e pela constante busca por adaptar-se às necessidades específicas dos segurados, proporcionando segurança e tranquilidade."
        },
        {
            imagem: liberyBanner,
            texto: "Liberty Seguros é reconhecida por sua abordagem inovadora e compromisso com a proteção dos clientes. Oferece uma variedade de seguros, desde automóveis até residenciais e empresariais, com foco na personalização e na cobertura abrangente. Sua reputação é construída sobre valores de confiança, transparência e excelência no atendimento, proporcionando soluções flexíveis e adaptadas às necessidades."
        },
        {
            imagem: mapfreBanner,
            texto: "Mapfre é uma seguradora global conhecida por sua ampla gama de serviços e forte presença em diversos países. Reconhecida pela solidez e excelência em seguros, oferece desde seguros de automóveis e residenciais até seguros corporativos e de vida. Sua reputação é respaldada pela dedicação ao cliente, pela inovação constante e pela busca por soluções adaptadas às necessidades individuais, proporcionando segurança e confiança."
        },
        {
            imagem: portoBanner,
            texto: "A Porto Seguro é uma das principais seguradoras do Brasil, reconhecida pela excelência em serviços e inovação no mercado de seguros. Oferece uma ampla gama de produtos, desde seguros automotivos e residenciais até seguros empresariais e de vida, sempre priorizando a qualidade no atendimento e a oferta de soluções completas. Sua reputação é construída sobre valores de confiança, transparência e compromisso."
        },
        {
            imagem: sompoBanner,
            texto: "A Sompo Seguros é reconhecida por sua expertise e compromisso com a excelência no mercado de seguros. Oferece uma ampla variedade de produtos, desde seguros automotivos e residenciais até seguros corporativos e de vida, destacando-se pela qualidade no atendimento e pela abrangência de suas coberturas. Sua reputação é respaldada por sua capacidade de oferecer soluções personalizadas e adaptadas às necessidades."
        },
        {
            imagem: suhaiBanner,
            texto: "A Suhai Seguradora é reconhecida por sua especialização em seguros exclusivamente focados em roubo e furto de veículos. Destaca-se por oferecer proteção específica e abrangente nesse segmento, com agilidade no atendimento e processos simplificados. Sua reputação é respaldada por seu compromisso em garantir coberturas específicas para situações de risco, proporcionando aos segurados segurança e confiança."
        },
        {
            imagem: tokioBanner,
            texto: "A Tokio Marine é uma seguradora global de renome, reconhecida pela excelência e inovação em seus serviços. Oferece uma ampla gama de produtos, desde seguros automotivos e residenciais até seguros corporativos e de vida, destacando-se pela qualidade no atendimento e pela abrangência de suas coberturas. Sua reputação é respaldada por décadas de experiência, pela capacidade de oferecer soluções personalizadas."
        },
        {
            imagem: zurichBanner,
            texto: "A Zurich Seguros é uma das principais seguradoras globais, reconhecida pela sua solidez e excelência em serviços. Oferece uma ampla variedade de produtos, desde seguros automotivos e residenciais até seguros corporativos e de vida, destacando-se pela qualidade no atendimento e pela abrangência de suas coberturas. Sua reputação é respaldada por um compromisso contínuo com a inovação, adaptando-se às necessidades."
        },
    ]

    const returnSegundaEtapa = () => {
        switch (objEmail.tipo) {
            case "seguro-auto":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.placaCarro} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    placaCarro: event.target.value
                                  }));
                            }} type="text" placeholder="Placa do carro" className="input input-bordered w-full" />
                        <input value={objEmail.marca} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    marca: event.target.value
                                  }));
                            }} type="text" placeholder="Marca" className="input input-bordered w-full" />
                        <input value={objEmail.modelo} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    modelo: event.target.value
                                  }));
                            }} type="text" placeholder="Modelo" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-residencial":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.cidade} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cidade: event.target.value
                                  }));
                            }} type="text" placeholder="Cidade" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-vida":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.profissao} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    profissao: event.target.value
                                  }));
                            }} type="text" placeholder="Profissão" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-empresa":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.cnpj} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cnpj: event.target.value
                                  }));
                            }} type="text" placeholder="CNPJ" className="input input-bordered w-full" />
                        <input value={objEmail.ramoEmpresa} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    ramoEmpresa: event.target.value
                                  }));
                            }} type="text" placeholder="Ramo da empresa" className="input input-bordered w-full" />
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div>
            <div className="bg-bgDivAsseguradoras h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivAsseguradoraMobile bg-cover">
                {isMobile() ? null : (
                    <div className="modal-box rounded-lg p-5 flex items-center flex-col">
                    <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                    <div className="w-full">
                        <div className="flex items-center w-full mt-8">
                            <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                            <hr className="w-full h-[2px] bg-primary" />
                            <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                        </div>
                        {etapa === 0 ? (
                            <div className="w-full mt-5 flex flex-col gap-5">
                                <select value={objEmail.tipo} onChange={(event) => {
                                    setObjEmail(prevState => ({
                                        ...prevState,
                                        tipo: event.target.value
                                      }));
                                }} className="select select-bordered w-full text-black font-medium">
                                    <option value="" disabled selected>Qual o seguro?</option>
                                    <option value="seguro-auto">Seguro Auto</option>
                                    <option value="seguro-residencial">Seguro Residencial</option>
                                    <option value="seguro-vida">Seguro de Vida</option>
                                    <option value="seguro-empresa">Seguro Empresa</option>
                                </select>
                                <input value={objEmail.nome} onChange={(event) => {
                                    setObjEmail(prevState => ({
                                        ...prevState,
                                        nome: event.target.value
                                      }));
                                }} type="text" placeholder="Nome" className="input input-bordered w-full" />
                                <input value={objEmail.cpf} onChange={(event) => {
                                    setObjEmail(prevState => ({
                                        ...prevState,
                                        cpf: event.target.value
                                      }));
                                }} type="text" placeholder="CPF" className="input input-bordered w-full" />
                                <input value={objEmail.email} onChange={(event) => {
                                    setObjEmail(prevState => ({
                                        ...prevState,
                                        email: event.target.value
                                      }));
                                }} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                                <input value={objEmail.telefone} onChange={(event) => {
                                    setObjEmail(prevState => ({
                                        ...prevState,
                                        telefone: event.target.value
                                      }));
                                }} type="text" placeholder="Telefone" className="input input-bordered w-full" />
                                <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                            </div>
                        ) : (
                            <div>
                                {returnSegundaEtapa()}
                                <div className="w-full mt-5 grid grid-cols-2 gap-5">
                                    <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                                    <button onClick={() => {
                                        postEmail({
                                            to: "vendas1@zuccoseguros.com.br",
                                            replyTo: objEmail.email,
                                            subject: "Contato para Zucco",
                                            html: {
                                                nome: objEmail.nome,
                                                tipo: objEmail.tipo,
                                                cpf: objEmail.cpf,
                                                telefone: objEmail.telefone,
                                                email: objEmail.email,
                                                dataNascimento: objEmail.dataNascimento,
                                                placaCarro: objEmail.placaCarro,
                                                marca: objEmail.marca,
                                                modelo: objEmail.modelo,
                                                cidade: objEmail.cidade,
                                                profissao: objEmail.profissao,
                                                cnpj: objEmail.cnpj,
                                                ramoEmpresa: objEmail.ramoEmpresa,
                                            },
                                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                          })
    
                                        setObjEmail({
                                            tipo: "",
                                            nome: "",
                                            cpf: "",
                                            email: "",
                                            telefone: "",
                                            dataNascimento: "",
                                            placaCarro: "",
                                            marca: "",
                                            modelo: "",
                                            cidade: "",
                                            profissao: "",
                                            cnpj: "",
                                            ramoEmpresa: ""
                                        })
    
                                            const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
                    
                                            if (modal) {
                                              modal.close();
                                              setEtapa(0);
                                            } else {
                                              console.error("Element with ID 'modal_proposta' not found.");
                                            }
                                    }} className="btn btn-primary text-white">Enviar Proposta</button>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
                )}
            </div>

            <h3 className="font-semibold text-2xl flex gap-2 px-48 leading-[16px] mt-24 mobile:text-base mobile:px-3 mobile:mt-14">Conheça as asseguradoras que confiam em nosso trabalho.</h3>
            <img src={isMobile() ? bgSobreNosMobilePagina : bgAsseguradoras} alt="Asseguradoras" className="mobile:w-full" />

            <div className="px-48 grid grid-cols-4 mt-[-1120px] gap-5 mobile:px-3 mobile:flex mobile:flex-col mobile:mt-[-1830px]">
                {lista.map((x) => (
                    <div className="shadow-shadow bg-white flex flex-col p-5 rounded-lg gap-3">
                        <img src={x.imagem} alt="Asseguradora" />
                        <h3 className="font-medium text-base text-justify leading-tight mobile:text-xs">{x.texto}</h3>
                    </div>
                ))}
            </div>

            <Propaganda />
        </div>
    )
}