import { useEffect, useState } from "react";

import bgCobertura from '../assets/bgHomeCardSeguros.png'
import guincho from '../assets/guincho.png'
import colisao from '../assets/colisao.png'
import corporais from '../assets/corporais.png'
import estetico from '../assets/estetico.png'
import fatalidade from '../assets/fatalidade.png'
import fipe from '../assets/fipe.png'
import materiais from '../assets/materiais.png'
import reserva from '../assets/reserva.png'
import servico from '../assets/servico.png'

import { CardCobertura } from "../components/CardCobertura";
import { CardClientes } from "../components/CardClientes";
import { Propaganda } from "../components/Propaganda";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { listaClientes } from "../shared/utils/contantes";
import { postEmail } from "../service/api";

const SeguroAuto: React.FC = () => {

    const lista = [
        {
            img: guincho,
            titulo: "Guincho ilimitado 24h",
            texto: "O guincho 24 horas oferece assistência imediata em casos de pane ou acidente."
        },
        {
            img: colisao,
            titulo: "Cobertura para colisão",
            texto: "A cobertura de colisão assegura reparos ou substituição do veículo em caso de danos decorrentes de colisões."
        },
        {
            img: servico,
            titulo: "Serviços para seu veículo",
            texto: "Oferecemos serviços abrangentes para seu veículo, desde manutenção até reparos, preservando seu valor."
        },
        {
            img: reserva,
            titulo: "Carro reserva",
            texto: "Proporcionamos tranquilidade com o serviço de carro reserva, assegurando mobilidade."
        },
        {
            img: materiais,
            titulo: "Danos Materiais",
            texto: "cobrem prejuízos causados a terceiros em acidentes pelos quais o segurado é responsável, incluindo danos a veículos."
        },
        {
            img: corporais,
            titulo: "Danos Corporais",
            texto: "cobrem lesões ou danos físicos causados a terceiros em acidentes pelos quais o segurado é responsável."
        },
        {
            img: fipe,
            titulo: "Indenização valor Fipe",
            texto: "É uma modalidade de cobertura no seguro de automóvel que garante a compensação pelo valor do veículo."
        },
        {
            img: fatalidade,
            titulo: "Cobertura por Fatalidade",
            texto: "oferece indenização em caso de falecimento do segurado devido a acidente coberto."
        },
        {
            img: estetico,
            titulo: "Danos Estéticos",
            texto: "abrangem custos de reparos que não comprometem a funcionalidade do veículo, como exemplo de arranhões."
        },
    ]
    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        dataNascimento: "",
        placaCarro: "",
        marca: "",
        modelo: "",
    })
    const [accordionState, setAccordionState] = useState(1);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
 
    return (
        <div>
            <div className="bg-bgDivSeguroAuto h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivSeguroAutoMobile bg-cover">
                {isMobile() ? null : (
                    <div className="modal-box rounded-lg p-5 flex items-center flex-col bg-white">
                    <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                    <div className="w-full">
                    <div className="flex items-center w-full mt-8">
                        <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                        <hr className="w-full h-[2px] bg-primary" />
                        <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                    </div>
                    {etapa === 0 ? (
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full bg-white" />
                            <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                        </div>
                    ) : (
                        <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.placaCarro} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    placaCarro: event.target.value
                                  }));
                            }} type="text" placeholder="Placa do carro" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.marca} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    marca: event.target.value
                                  }));
                            }} type="text" placeholder="Marca" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.modelo} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    modelo: event.target.value
                                  }));
                            }} type="text" placeholder="Modelo" className="input input-bordered w-full bg-white" />
                        <div className="w-full mt-5 grid grid-cols-2 gap-5">
                            <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                            <button onClick={() => {
                                postEmail({
                                    to: "vendas1@zuccoseguros.com.br",
                                    replyTo: objEmail.email,
                                    subject: "Contato para Zucco",
                                    html: {
                                        nome: objEmail.nome,
                                        tipo: "Seguro Auto",
                                        cpf: objEmail.cpf,
                                        telefone: objEmail.telefone,
                                        email: objEmail.email,
                                        placaCarro: objEmail.placaCarro,
                                        dataNascimento: objEmail.dataNascimento,
                                        marca: objEmail.marca,
                                        modelo: objEmail.modelo,
                                    },
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                  })
                                setObjEmail({
                                    tipo: "",
                                    nome: "",
                                    cpf: "",
                                    email: "",
                                    telefone: "",
                                    dataNascimento: "",
                                    placaCarro: "",
                                    marca: "",
                                    modelo: "",
                                })
                                setEtapa(0)
                            }} className="btn btn-primary text-white">Enviar Proposta</button>
                        </div>
                    </div>
                    )}
                    </div>
                </div>
                )}
            </div>

                <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14">Aqui alguma das coberturas para <span className="text-primary">você e o seu veículo</span></h3>
                <img src={bgCobertura} alt="Nossa história" className="mobile:h-60 mobile:object-cover" />
                <CardCobertura lista={lista} />

                <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:mt-14 mobile:text-base">Saiba o que <span className="text-primary">nossos clientes falam</span></h3>
                <CardClientes lista={listaClientes} />

                <Propaganda />
                
                <div className="px-48 mt-24 mb-40 mobile:px-3 mobile:mb-20">
                <h2 className="font-semibold text-2xl mobile:text-base">Dúvidas sobre o <span className="text-primary">Seguro Auto?</span></h2>
                <div className="flex flex-col gap-1 mt-3 mobile:mt-0">
                    <div onClick={() => setAccordionState(1)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 1}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Como funciona o Seguro Auto?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col mobile:text-xs"> 
                            <p className="text-justify font-medium leading-tight">
                                O seguro auto é estabelecido por meio de contrato específico. Por meio deste, o segurado conta com indenizações, reparos ou outros serviços em seu veículo quando houver necessidade.
                                Nunca se sabe quando um acidente acontecerá, de forma que se precaver é sempre a melhor opção quando se tem um veículo. É justamente neste ponto que os seguros se mostram necessários, já que o segurado não precisará lidar com gastos fora do orçamento.
                            </p>
                            <div>
                                <p className="text-justify font-semibold">
                                    Como são formulados o valor do seguro?
                                </p>
                                <p className="text-justify font-medium leading-tight">
                                    Os valores são formulados com base no valor de mercado do veículo segurado. Mas este não representa o único critério para estabelecimento de preços, já que os riscos a que um veículo está exposto também podem influenciar neste cálculo.
                                </p>
                            </div>
                                <p className="text-justify font-semibold">
                                        Perfil do Condutor:  <span className="text-justify font-medium">
                                        Idade, estado civil, tempo de habilitação são levados em consideração.
                                    </span>
                                </p>
                                <p className="text-justify font-semibold">
                                        Localização: <span className="text-justify font-medium">
                                        O local onde o carro é mantido e a região de circulação influenciam no preço. 
                                    </span>
                                </p>
                                <p className="text-justify font-semibold">
                                        Utilização: <span className="text-justify font-medium">
                                        Se o carro é utilizado para trabalho ou lazer, isso pode afetar o custo do seguro.
                                    </span>
                                </p>                                
                                <p className="text-justify font-semibold">
                                        Histórico de sinistros: <span className="text-justify font-medium">
                                        Um histórico sem sinistros ou com poucos sinistros costuma resultar em prêmios mais baixos.
                                    </span>
                                </p>
                                <p className="text-justify font-semibold">
                                    Franquia: <span className="text-justify font-medium">
                                        A escolha da franquia (a quantia que o segurado concorda em pagar em caso de sinistro) também influencia no valor do seguro. Franquias mais altas geralmente resultam em prêmios mais baixos.
                                    </span>
                                </p>
                                <p className="text-justify font-semibold">
                                    Coberturas: <span className="text-justify font-medium">
                                    As coberturas contratadas vão afetar diretamente o valor do seguro.                                
                                    </span>
                                </p>
                            <p className="text-justify font-medium leading-tight">
                                Cada seguradora utiliza diferentes métodos para avaliar esses fatores, e a combinação única de características do motorista e do veículo determina o prêmio do seguro. Por este motivo é importante comparar em todas as seguradoras para encontrar a melhor oferta que atenda às necessidades específicas do segurado.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(2)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 2}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Quais fatores interferem no valor do meu Seguro Auto?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">
                                Para calcular o seguro do seu carro, fatores como marca, modelo e ano do veículo são essenciais, assim como a região de circulação e estacionamento, e o perfil e idade do condutor principal. Cláusulas, coberturas distintas e o valor da franquia também influenciam no preço final do seguro.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(3)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 3}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>O que é franquia de seguro?</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">A franquia é o montante estabelecido no contrato que o cliente deve pagar quando aciona a seguradora. Esse valor se aplica a sinistros de perda parcial, excluindo casos de incêndio, explosão acidental ou raios.</p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(4)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 4}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Em caso de alagamento, os veículos na garagem estão cobertos pelo Seguro Auto?</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">A cobertura compreensiva indeniza danos materiais ao veículo por submersão em água doce, como enchentes ou inundações, incluindo situações de veículos guardados em subsolos.</p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                </div>
                </div>
             </div>
    )
}

export default SeguroAuto;