import { useNavigate } from "react-router-dom";
import { isMobile } from "../shared/utils/javascript";

type CardSeguroProps = {
    titulo: string,
    texto: string,
    imagem: string,
    link: string,
}

export const CardSeguro = ({titulo, texto, imagem, link} : CardSeguroProps) => {

    const navigate = useNavigate()

    const palavras = titulo.split(' ');
  
    const ultimaPalavra = palavras[palavras.length - 1];
  
    const restanteDaFrase = titulo.slice(0, titulo.lastIndexOf(ultimaPalavra));
  

    return (
        <div className="rounded-lg shadow-shadow p-5 flex flex-col gap-2 cursor-pointer bg-white">
            {isMobile() ? (
                 <div className="h-40 w-80 mobile:w-64">
                    <img className="w-full h-full object-cover" src={imagem} alt={titulo} onClick={() => navigate(link)} />
                </div>
            ) : (
                <img className="w-full h-full" src={imagem} alt={titulo} onClick={() => navigate(link)} />
            )}
            <h3 className="font-semibold text-2xl flex gap-2 mobile:text-base">{restanteDaFrase} <h3 className="text-primary">{ultimaPalavra}</h3></h3>
            <h4 className="text-justify font-medium leading-tight mobile:text-sm">{texto}</h4>
            <div className="grid grid-cols-2 gap-2 mt-1">
            <button 
                onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                >
                    <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                    Whatsapp
                </button>
            <button 
                    onClick={() => {
                        const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;

                        if (modal) {
                          modal.showModal();
                        } else {
                          console.error("Element with ID 'modal_proposta' not found.");
                        }
                    }}
                    className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                >
                    <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                    Cotar seguro
                </button>
            </div>
        </div>
    )
}