import { useEffect, useRef, useState } from "react";
import { Slider } from "../components/Slider";
import { CardSeguro } from "../components/CardSeguro";

import seguroAuto from '../assets/seguroAuto.png'
import seguroEmpresa from '../assets/seguroEmpresa.png'
import seguroResidencial from '../assets/seguroResidencial.png'
import seguroVida from '../assets/seguroVida.png'
import mapfre from '../assets/mapfre.png'
import porto from '../assets/porto.png'
import somos from '../assets/somos.png'
import suhai from '../assets/suhai.png'
import tokio from '../assets/tokio.png'
import zurich from '../assets/zurich.png'
import msig from '../assets/msig.png'
import liberty from '../assets/liberty.png'
import hdiseguros from '../assets/hdiseguros.png'
import azul from '../assets/azul.png'
import Alirio from '../assets/Alirio.png'
import bradesco from '../assets/bradesco.png'
import allianz from '../assets/allianz.png'
import consorcioAutomotivo from '../assets/consorcioAutomotivo.png'
import consorcioImobiliario from '../assets/consorcioImobiliario.png'

import { ModalConsorcio } from "../components/ModalConsorcio";
import { useNavigate } from "react-router-dom";
import { isMobile, scrollToTop } from "../shared/utils/javascript";

const Home: React.FC = () => {
    const navigate = useNavigate()

    const [accordionState, setAccordionState] = useState(1);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
          const scrollContainer = scrollContainerRef.current;
    
          // Largura total do conteúdo
          const contentWidth = scrollContainer.scrollWidth;
    
          // Largura visível do contêiner
          const containerWidth = scrollContainer.clientWidth;
    
          // Defina a posição inicial do scroll para o meio
          scrollContainer.scrollLeft = (contentWidth - containerWidth) / 2;
        }
      }, []);

      useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div>
            <ModalConsorcio />
            <Slider />

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:text-base mobile:px-2 mobile:mb-[-7px] mobile:mt-16">Descubra os <span className="text-primary">seguros disponíveis</span> para você agora mesmo!</h3>
            <div className="bg-bgDivCard h-80" />
                <div className="grid grid-cols-4 gap-5 px-48 mt-[-290px] mobile:px-2 mobile:overflow-x-auto mobile:pb-3 mobile:flex">
                    <CardSeguro link="seguro-auto" imagem={seguroAuto} titulo="Seguro Auto" texto="O seguro de automóvel é um serviço que protege seu veículo contra danos, roubo e oferece assistência." />
                    <CardSeguro link="seguro-residencial" imagem={seguroResidencial} titulo="Seguro Residencial" texto="O seguro residencial protege sua casa e seus pertences contra danos, roubo e eventos inesperados." />
                    <CardSeguro link="seguro-empresa" imagem={seguroEmpresa} titulo="Seguro Empresarial" texto="O seguro empresarial protege sua empresa contra danos, responsabilidade civil e imprevistos." />
                    <CardSeguro link="seguro-vida" imagem={seguroVida} titulo="Seguro de Vida" texto="O seguro de vida oferece segurança financeira para sua família em caso de imprevistos em momentos difícies." />
                </div>

            <div className="bg-bgDivNossaHistoria h-[560px] mt-32 mobile:h-[450px] mobile:mt-20 mobile:bg-bgDivNossaHistoriaMobile" />
            <div className="px-48 mt-[-510px] flex flex-col gap-5 mobile:px-0 mobile:mt-[-430px]">
                <div className="mobile:px-2 flex flex-col gap-5">
                    <div onClick={() => setAccordionState(1)} className="collapse collapse-arrow bg-white max-w-[30vw] mobile:max-w-full">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 1}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:gap-2 mobile:items-center">
                            <i className="ri-git-repository-line"></i>
                            <h4 className="mobile:text-sm">Conheça um pouco de nossa história</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">A Zucco Corretora de seguros está no mercado desde 2003, atuando em vários ramos de seguros, atendendo pessoas físicas e jurídicas.
                            Nosso objetivo é ser a corretora de seguros mais confiável, proporcionando tranquilidade e segurança aos nossos clientes. Buscamos constantemente excelência em atendimento ao cliente, oferecendo soluções personalizadas e acessíveis que superem as expectativas.
                            </p>
                        </div>
                    </div>
                    <div onClick={() => setAccordionState(2)} className="collapse collapse-arrow bg-white max-w-[30vw] mobile:max-w-full">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 2}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:gap-2 mobile:items-center">
                            <i className="ri-question-line"></i>
                            <h4 className="mobile:text-sm">O que é a Zucco Seguros</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">A Zucco Seguros tem parceria com Seguradoras sólidas e confiáveis agindo sempre com transparência e ética em todas as transações, fornecendo informações claras e compreensíveis sobre as apólices.
                                Buscamos sempre manter-nos atualizados sobre as tendências e mudanças no mercado de seguros bem como compreender os diferentes tipos de seguros e suas coberturas.
                            </p>
                        </div>
                    </div>
                    <div onClick={() => setAccordionState(3)} className="collapse collapse-arrow bg-white max-w-[30vw] mobile:max-w-full">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 3}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:gap-2 mobile:items-center">
                            <i className="ri-building-2-line"></i>
                            <h4 className="mobile:text-sm">A nossa essência como empresa</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">A Zucco Corretora de Seguros, líder no mercado desde 2003, destaca-se pela confiança, inovação e excelência. Comprometida com a satisfação do cliente, busca constantemente aprimorar seus serviços, mantendo parcerias sólidas e transparentes. Visamos ser verdadeiros parceiros, proporcionando tranquilidade através de soluções personalizadas e tecnologias avançadas, moldando o futuro do setor.</p>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-24 mt-8 mobile:overflow-x-auto mobile:flex mobile:gap-2 mobile:mt-5 mobile:pb-3" ref={scrollContainerRef}>
                    <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                        <h1 className="font-slab text-5xl mobile:text-3xl text-center">+20 Anos</h1>
                        <h4 className="leading-tight font-medium text-center">A mais de 20 anos no mercado trazendo segurança a nossos clientes</h4>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                        <h1 className="font-slab text-5xl mobile:text-3xl text-center">+3.000 pessoas</h1>
                        <h4 className="leading-tight font-medium text-center">Mais de 3 mil pessoas que passaram por nossa empresa confiam em nosso trabalho.</h4>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                        <h1 className="font-slab text-5xl mobile:text-3xl text-center">+10 parceiros</h1>
                        <h4 className="leading-tight font-medium text-center">AS principais Seguradoras do páis confiam em nosso trabalho.</h4>
                    </div>
                </div>
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:mt-16 mobile:px-3 mobile:leading-5 mobile:text-base mobile:mb-[-7px]">Aqui é você quem decide entre as <span className="text-primary">melhores seguradoras</span> do Brasil.</h3>
                <div className="bg-bgDivSeguradoras h-80 mobile:bg-seguros mobile:h-[450px]" onClick={() => navigate('/asseguradoras')} />
                {isMobile() ? null : (
                    <div className="px-48 mt-[-280px] mobile:px-3">
                        <div className="flex gap-24 flex-wrap">
                            <img className="cursor-pointer" onClick={() => navigate('/asseguradoras')} src={allianz} alt="Allianz" />
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={azul} alt="Azul" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={Alirio} alt="Alirio" />
                            </div>
                            <img className="cursor-pointer" onClick={() => navigate('/asseguradoras')} src={bradesco} alt="Bradesco" />
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={hdiseguros} alt="HDI Seguros" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={liberty} alt="Liberty Seguros" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={msig} alt="MSIG" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={mapfre} alt="Mapfre" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={porto} alt="Porto" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={somos} alt="Sompo Seguros" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={suhai} alt="Suhai" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={tokio} alt="Tokio Marine Seguradora" />
                            </div>
                            <div onClick={() => navigate('/asseguradoras')} className="bg-white p-2 rounded-xl cursor-pointer">
                                <img src={zurich} alt="Zurich" />
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="mt-28 px-48 flex justify-center gap-28 mobile:px-3 mobile:gap-5 mobile:flex-col mobile:mt-20">
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 15.1%)"}} className="bg-white p-5 flex flex-col rounded-xl gap-3 shadow-shadow">
                        <h3 className="flex gap-2 text-2xl font-semibold mobile:text-base">Consórcio <h3 className="text-primary">Automotívo</h3></h3>
                        <img src={consorcioAutomotivo} alt="Consórcio Automotivo" className="cursor-pointer" onClick={() => navigate("/consorcio-auto")} />
                        <h4 className="text-justify font-medium leading-tight mobile:text-sm">O consórcio de veículo oferece uma maneira planejada de adquirir um carro, sem juros, com flexibilidade de parcelas e possibilidade de contemplação por sorteio ou lance, proporcionando uma compra mais acessível e organizada.</h4>
                        <div className="grid grid-cols-2 gap-2 mt-1">
                        <button 
                            onClick={() => {
                                const modal = document.getElementById('modal_consorcio') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }}
                            className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                        >
                            <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                            Cotar consórcio
                        </button>
                        <button 
                            onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                            className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                        >
                            <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                            Whatsapp
                        </button>
                        </div>
                    </div>
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 15.1%)"}} className="bg-white p-5 flex flex-col rounded-xl gap-3 shadow-shadow">
                        <h3 className="flex gap-2 text-2xl font-semibold mobile:text-base">Consórcio <h3 className="text-primary">Imobiliário</h3></h3>
                        <img src={consorcioImobiliario} alt="Consórcio Automotivo" className="cursor-pointer" onClick={() => navigate("/consorcio-imobiliario")} />
                        <h4 className="text-justify font-medium leading-tight mobile:text-sm">O consórcio imobiliário é uma alternativa para adquirir imóveis de forma planejada, sem juros, com parcelas acessíveis e contemplação por sorteio ou lance, proporcionando uma maneira econômica de realizar o sonho da casa própria.</h4>
                        <div className="grid grid-cols-2 gap-2 mt-1">
                        <button 
                            onClick={() => {
                                const modal = document.getElementById('modal_consorcio') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }}
                            className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                        >
                            <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                            Cotar consórcio
                        </button>
                        <button 
                            onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                            className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight mobile:text-xs"
                        >
                            <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                            Whatsapp
                        </button>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Home;