import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "../shared/utils/javascript";

type ListaType = {
    img: string,
    titulo: string,
    texto: string
}
type CardCoberturaType = {
    lista: ListaType[],
}

export const CardCobertura = ({lista}: CardCoberturaType) => {

    const cardsPorGrupo = 4;
    const totalGrupos = Math.ceil(lista.length / cardsPorGrupo);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerRefMobile = useRef<HTMLDivElement>(null);
    const [grupoAtual, setGrupoAtual] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0);

    
    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        const ele = containerRef.current;
        if (!ele) {
            return;
        }
        const startPos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        const handleMouseMove = (e: React.MouseEvent) => {
            const dx = e.clientX - startPos.x;
            const newScrollLeft = ele.scrollLeft - dx;
            if(dx > 0) {
                smoothScrollTo(ele, ele.scrollLeft - 1540);
            } else {
                smoothScrollTo(ele, ele.scrollLeft + 1540);
            }
            setScrollLeft(newScrollLeft);
            updateCursor(ele);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove as any);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor(ele);
        };

        document.addEventListener('mousemove', handleMouseMove as any);
        document.addEventListener('mouseup', handleMouseUp);
    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        const ele = containerRef.current;
        if (!ele) {
            return;
        }
        const touch = e.touches[0];
        const startPos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: touch.clientX,
            y: touch.clientY,
        };

        const handleTouchMove = (e: React.TouchEvent) => {
            const touch = e.touches[0];
            const dx = touch.clientX - startPos.x;
            ele.scrollLeft = startPos.left - dx;
            const newScrollLeft = ele.scrollLeft - dx;
            setScrollLeft(newScrollLeft);
            updateCursor(ele);
        };

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove as any);
            document.removeEventListener('touchend', handleTouchEnd);
            resetCursor(ele);
        };

        document.addEventListener('touchmove', handleTouchMove as any);
        document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const smoothScrollTo = (ele: any, targetLeft: number) => {
        const duration = 500;
        const startLeft = ele.scrollLeft;
        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;

            ele.scrollLeft = easeInOutQuad(elapsedTime, startLeft, targetLeft - startLeft, duration);

            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const updateCursor = (ele: any) => {
        if(lista.length <= 4) {
            return
        }
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
    };

    const resetCursor = (ele: any) => {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
    };

    useEffect(() => {
        const ele = containerRef.current;

        if (!ele) {
            return;
        }

        switch (grupoAtual) {
            case 0:
                smoothScrollTo(ele, 0);
              break;
            case 1:
                smoothScrollTo(ele, 1540);
              break;
            case 2:
                smoothScrollTo(ele, 3080);
              break;
            default:
                smoothScrollTo(ele, 0);
              break;
          }
    },[grupoAtual])

    useEffect(() => {
        const ele = containerRef.current;
    
        if (!ele) {
            return;
        }
        if(scrollLeft === 0) {
            setGrupoAtual(0)
            return
        }
    
        const newGrupoAtual = Math.floor((scrollLeft + 1540) / 1540);
        setGrupoAtual(newGrupoAtual);
    }, [scrollLeft]);

    const scrollInterval = 5000;

    useEffect(() => {
        const ele = containerRefMobile.current;
    
        if (!ele) {
            return;
        }
    
        const scrollAutomatically = () => {
            const currentScrollLeft = ele.scrollLeft;
            const newScrollLeft = currentScrollLeft + 380;
    
            const maxScrollLeft = ele.scrollWidth - ele.clientWidth;
            const adjustedScrollLeft = newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft;
    
            smoothScrollTo(ele, adjustedScrollLeft);
        };
    
        const intervalId = setInterval(() => {
            scrollAutomatically();
        }, scrollInterval);
    
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <div className="flex flex-row gap-5 mt-[-410px] overflow-x-auto mx-48 scroll-bar-none mobile:mx-0 mobile:mt-[-210px] mobile:pb-3 mobile:gap-3"
                ref={isMobile() ? containerRefMobile : containerRef}
                onMouseDown={lista.length > 4 || !isMobile() ? handleMouseDown : undefined}
                onTouchStart={lista.length > 4 || !isMobile() ? handleTouchStart : undefined}
            >
                {lista.map((x, index) => {
                    
                const palavras = x.titulo.split(' '); 

                const primeiraPalavra = palavras[0];
            
                const restanteDaFrase = palavras.slice(1).join(' ')

                    return (
                    <div key={index} className="shadow-shadow rounded-lg">
                        <div className="bg-white p-5 rounded-lg flex flex-col gap-2 max-h-[370px] h-full w-[365px]">
                            <img draggable={false} src={x.img} alt={x.titulo} />
                            <h2 className="text-lg font-medium mobile:text-base">{primeiraPalavra} <span className="text-primary">{restanteDaFrase}</span></h2>
                            <h3 className="font-medium text-base text-justify leading-tight mobile:text-xs">{x.texto}</h3>
                        </div>
                    </div>
                )})}
            </div>
            {lista.length <= 4 ? null : (
                <div className="flex gap-4 justify-center mt-4 mobile:hidden">
                    {Array.from({ length: totalGrupos }).map((_, index) => (
                        <span
                            key={index}
                            onClick={() => setGrupoAtual(index)}
                            className={`pagina-indicador ${index === grupoAtual ? 'bg-primary w-2 h-2 rounded-full cursor-pointer' : 'w-2 h-2 bg-accent rounded-full cursor-pointer'}`}
                        ></span>
                    ))}
                </div>
            )}
        </>
    )
}