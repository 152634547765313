import { Carousel } from 'flowbite-react'
import { useEffect, useState } from 'react';
import { buscarSlide } from '../service/api';
import { isMobile } from '../shared/utils/javascript';

export const Slider = () => {

    const [slide, setSlide] = useState<any>();
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-primary dark:bg-gray-800"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
      };

      const handleGetSlide = async () => {
        const result = await buscarSlide()
        setSlide(result?.data)
      }

      useEffect(() => {
        handleGetSlide();
      },[])

      const findBanner = () => {
        const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
        const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 

        return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    console.log(findBanner())

    return (
        <div className="h-56 sm:h-64 2xl:h-[600px] mobile:h-[91vh]">
            <Carousel theme={estilos}>
              {findBanner()?.map((x: any) => (
                <img className='object-cover h-full' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
              ))}
            </Carousel>
        </div>
    )
}