import { useCallback, useEffect, useRef, useState } from "react";
import aspas from '../assets/aspas.png'
import aspasMobile from '../assets/aspasMobile.png'
import { isMobile } from "../shared/utils/javascript";

type ListaType = {
    titulo: string,
    texto: string,
    data: string
}
type CardCoberturaType = {
    lista: ListaType[],
}

export const CardClientes = ({lista}: CardCoberturaType) => {

    const containerRef = useRef<HTMLDivElement>(null);
    
    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        const ele = containerRef.current;
        if (!ele) {
            return;
        }
        const startPos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        const handleMouseMove = (e: React.MouseEvent) => {
            const dx = e.clientX - startPos.x;
            if(dx > 0) {
                smoothScrollTo(ele, ele.scrollLeft - 1540);
            } else {
                smoothScrollTo(ele, ele.scrollLeft + 1540);
            }
            updateCursor(ele);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove as any);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor(ele);
        };

        document.addEventListener('mousemove', handleMouseMove as any);
        document.addEventListener('mouseup', handleMouseUp);
    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        const ele = containerRef.current;
        if (!ele) {
            return;
        }
        const touch = e.touches[0];
        const startPos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: touch.clientX,
            y: touch.clientY,
        };

        const handleTouchMove = (e: React.TouchEvent) => {
            const touch = e.touches[0];
            const dx = touch.clientX - startPos.x;
            ele.scrollLeft = startPos.left - dx;
            updateCursor(ele);
        };

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove as any);
            document.removeEventListener('touchend', handleTouchEnd);
            resetCursor(ele);
        };

        document.addEventListener('touchmove', handleTouchMove as any);
        document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const smoothScrollTo = (ele: any, targetLeft: number) => {
        const duration = 500;
        const startLeft = ele.scrollLeft;
        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;

            ele.scrollLeft = easeInOutQuad(elapsedTime, startLeft, targetLeft - startLeft, duration);

            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const updateCursor = (ele: any) => {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
    };

    const resetCursor = (ele: any) => {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
    };

    const scrollInterval = 8000;

    useEffect(() => {
        const ele = containerRef.current;
    
        if (!ele) {
            return;
        }
    
        const scrollAutomatically = () => {
            const currentScrollLeft = ele.scrollLeft;
            const newScrollLeft = currentScrollLeft + 700;
    
            const maxScrollLeft = ele.scrollWidth - ele.clientWidth;
            const adjustedScrollLeft = newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft;
    
            smoothScrollTo(ele, adjustedScrollLeft);
        };
    
        const intervalId = setInterval(() => {
            scrollAutomatically();
        }, scrollInterval);
    
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="flex flex-row gap-5 mt-7 overflow-x-auto scroll-bar-none mobile:mt-5"
            ref={isMobile() ? undefined : containerRef}
            onMouseDown={isMobile() ? undefined : handleMouseDown}
            onTouchStart={isMobile() ? undefined : handleTouchStart}
        >
            {lista.map((x, index) => {
                return (
                <div key={index} className="shadow-shadow rounded-lg mb-2">
                    <div className="bg-white p-5 rounded-lg flex flex-col gap-2 max-h-[370px] w-[450px] mobile:w-[80vw]" style={{background: "linear-gradient(180deg, rgba(241, 241, 241, 0.95) 0%, #FFF 22.92%)"}}>
                        <div className="flex items-center gap-3 h-14 mobile:h-min">
                            <img src={isMobile() ? aspasMobile : aspas} alt="Aspas" />
                            <h2 className="text-2xl font-medium mobile:text-base">{x.titulo}</h2>
                        </div>
                        <h3 className="font-medium text-base text-justify mt-3 mobile:text-sm">{x.texto}</h3>
                        <h4 className="mt-2 text-base-300 font-medium self-end mobile:text-sm">{x.data}</h4>
                    </div>
                </div>
            )})}
        </div>
    )
}