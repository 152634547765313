import Logo from '../assets/logoPretoBranco.png'
import LitoralCar from '../assets/litoralcar.png.png'
import { isMobile } from '../shared/utils/javascript'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { InfosLoja } from '../shared/types'
import { getInfosLoja } from '../service/api'

export const Footer = () => {

    const navigate = useNavigate();
    const [infosLojaResult, setInfosLojaResult] = useState<InfosLoja>()

    const infosLoja = async () => {
        try {
            const result = await getInfosLoja();
            setInfosLojaResult(result?.data)
        } catch {

        }
    }

    useEffect(() => {
        infosLoja()
    },[])

    return (
        <div className="bg-black py-10 px-48 mt-20 flex flex-col items-center gap-6 mobile:px-0 mobile:py-0">
                {isMobile() ? (
                <div className="flex justify-between w-full">
                    <div>
                        <div className='flex gap-5 flex-wrap px-3 py-3'>
                            <div className='flex flex-col gap-2'>
                                <h3 className='text-white text-sm font-semibold'>Números</h3>
                                <h4 className='text-white text-xs' onClick={() => {window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}><i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i> (47) 99129-1002</h4>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <h3 className='text-white text-sm font-semibold'>E-mails</h3>
                                <h4 className='text-white text-xs' onClick={() => {
                                    window.open("mailto:vendas1@zuccoseguros.com.br")
                                }}><i className="ri-mail-send-line"></i> vendas1@zuccoseguros.com.br</h4>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <h3 className='text-white text-sm font-semibold'>Localização</h3>
                                <h4 className='text-white text-xs' onClick={() => {
                                    window.open("mailto:vendas1@zuccoseguros.com.br")
                                }}><i className="ri-mail-send-line"></i> R. João Bauer, 444 - Centro 1, Brusque - SC</h4>
                            </div>
                        </div>
                        <div className='bg-info-content px-3 pt-3 pb-1 flex flex-col'>
                            <h4 className='text-white text-xs'>Copyright © 2023 Todos os direitos reservados para</h4>
                            <div className='flex gap-2'>
                                <h4 className='text-white text-xs mt-[5px]'>Zucco Seguros - Desenvolvido </h4>
                                <img onClick={() => window.open("https://www.litoralcar.com.br/")} src={LitoralCar} alt="LitoralCar" />
                            </div>
                            <h4 className='text-white text-xs underline self-center mt-3'>Politica de privacidade e termos de uso</h4>
                        </div>
                    </div>
                </div>
                ) : (
                    <>
                    <div className="flex justify-between w-full">
                        <div className='flex gap-24'>
                            <div className="flex flex-col gap-4">
                                <h3 className="text-white text-lg font-medium">Links Rápidos</h3>
                                <h4 onClick={() => {
                                    navigate("/")
                                }} className="text-white font-medium cursor-pointer">Início</h4>
                                <h4 onClick={() => {
                                    navigate("/sobre-nos")
                                }} className="text-white font-medium cursor-pointer">Sobre nós</h4>
                                <h4 onClick={() => {
                                    navigate("/asseguradoras")
                                }} className="text-white font-medium cursor-pointer">Seguradoras</h4>
                                <h4 onClick={() => {
                                    navigate("/consorcio-auto")
                                }} className="text-white font-medium cursor-pointer">Consórcio Auto</h4>
                                <h4 onClick={() => {
                                    navigate("/consorcio-imobiliario")
                                }} className="text-white font-medium cursor-pointer">Consórcio Imobiliario</h4>
                            </div>
                            <div className="flex flex-col gap-4">
                                <h3 className="text-white text-lg font-medium">Outros assuntos</h3>
                                <h4 onClick={() => {
                                    navigate("/seguro-auto")
                                }} className="text-white font-medium cursor-pointer">Seguro Auto</h4>
                                <h4 onClick={() => {
                                    navigate("/seguro-residencial")
                                }} className="text-white font-medium cursor-pointer">Seguro Residencial</h4>
                                <h4 onClick={() => {
                                    navigate("/seguro-vida")
                                }} className="text-white font-medium cursor-pointer">Seguro de Vida</h4>
                                <h4 onClick={() => {
                                    navigate("/seguro-empresa")
                                }} className="text-white font-medium cursor-pointer">Seguro Empresarial</h4>
                            </div>
                            <div className="flex flex-col gap-4">
                                <h3 className="text-white text-lg font-medium">Sobre a Zucco</h3>
                                <h4 onClick={() => window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)} className="text-white font-medium cursor-pointer"><i style={{ color: "white", cursor: "pointer" }} className="ri-whatsapp-line ri-lg"></i> (47) 99129-1002</h4>
                                <h4 onClick={() => window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlInstagram, "_blank")} className="text-white font-medium cursor-pointer"><i style={{ color: "white", cursor: "pointer" }} className="ri-instagram-line ri-lg"></i> Instagram</h4>
                                <h4 onClick={() => window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlFacebook, "_blank")}  className="text-white font-medium cursor-pointer"><i style={{ color: "white", cursor: "pointer" }} className="ri-facebook-circle-fill ri-lg"></i> Facebook</h4>
                            </div>
                        </div>
                        <div className='max-w-64 text-center'>
                        <img className='mx-auto' src={Logo} alt="Zucco Seguros" />
                        <div>
                            <div>
                            <h4 className='text-white mt-5 text-left'>Acompanhe a Zucco Seguros nas redes sociais</h4>
                            <div className='flex gap-9 mt-5'>
                                <i onClick={() => window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlFacebook, "_blank")} style={{ color: "white", cursor: "pointer" }} className="ri-facebook-circle-fill ri-lg"></i>
                                <i onClick={() => window.open("https://br.linkedin.com/in/cleandro-luis-zucco-824486279", "_blank")} style={{ color: "white", cursor: "pointer" }} className="ri-linkedin-box-fill ri-lg"></i>
                                <i onClick={() => window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlInstagram, "_blank")} style={{ color: "white", cursor: "pointer" }} className="ri-instagram-line ri-lg"></i>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <h5 className="text-white">© 2023 Zucco Seguros | Todos os direitos reservados</h5>
                </>
            )}  
        </div>
    )
}