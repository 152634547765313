import whats from '../assets/whatsapp.png'
import cotar from '../assets/cotar.png'
import { isMobile } from '../shared/utils/javascript';
import { ModalProposta } from './ModalProposta';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { postWhats } from '../service/api';
import axios from 'axios';

export const WhatssApp = () => {

    const location = useLocation();
    const [ip, setIP] = useState()

    useEffect(() => {
      console.log("A rota mudou para:", location.pathname);
  
    }, [location.pathname]);

    const getData = async () => {
      await axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
    };

    useEffect(() => {
        getData()
    },[])

    const retornoMobile = () => {
        if(location.pathname.includes("seguro") || location.pathname.includes("asseguradoras") || location.pathname.includes("consorcio")) {
            return (
                <div className='grid gap-3 grid-cols-2 justify-center px-2'>
                        <button 
                            onClick={() => {
                                const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }}
                            className="btn btn-primary text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight"
                        >
                            <i style={{color: "white"}} className="ri-group-2-fill ri-lg"></i>
                            Cotar seguro
                        </button>
                        <button
                        onClick={async () => {
                            try {
                                await postWhats({
                                    ip: ip,
                                    dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                    codVeiculo: 0
                                });
                            } catch {
                                //
                            }
                            window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)}}
                        className="btn btn-success text-base text-white p-2 font-medium rounded-xl h-min min-h-fit leading-tight"
                    >
                        <i style={{color: "white"}} className="ri-whatsapp-line ri-lg"></i>
                        Whatsapp
                    </button>
                    </div>
            )
        } else {
            return (
                <>
                    <img src={cotar} alt="Cotar Seguro" onClick={() => {
                        const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
        
                        if (modal) {
                            modal.showModal();
                        } else {
                            console.error("Element with ID 'modal_proposta' not found.");
                        }
                    }} />
                    <img src={whats} alt="WhatsApp" onClick={async () => {
                        try {
                            await postWhats({
                                ip: ip,
                                dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                codVeiculo: 0
                            });
                        } catch {
                            //
                        }
                        window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)
                    }} /> 
                </>
            )
        }
    }

    return (
        <>
        <ModalProposta />
            <div className={`fixed bottom-5 right-5 cursor-pointer z-50 gap-3 flex flex-col mobile:right-1 ${location.pathname.includes("seguro") || location.pathname.includes("asseguradoras") || location.pathname.includes("consorcio") ? "mobile:left-0 mobile:right-0 mobile:w-full" : ""}`}>
                {isMobile() ? retornoMobile() : (
                    <img src={whats} alt="WhatsApp" onClick={async () => {
                        try {
                            await postWhats({
                                ip: ip,
                                dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                codVeiculo: 0
                            });
                        } catch {
                            //
                        }
                        window.open(`https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!`)
                    }} />
                )}
            </div>
        </>
    )
}