import { useState } from "react";
import { postEmail } from "../service/api";

export const ModalConsorcio = () => {

    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        valor: "",
    })
    
    return (
        <dialog id="modal_consorcio" className="modal">
            <div className="modal-box rounded-lg p-5 flex items-center flex-col">
                <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                <div className="w-full mt-2">
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full" />
                            <input value={objEmail.valor} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    valor: event.target.value
                                  }));
                            }} type="text" placeholder="Valor da carta de consórcio" className="input input-bordered w-full" />
                            <button onClick={() => {
                                    postEmail({
                                        to: "vendas1@zuccoseguros.com.br",
                                        replyTo: objEmail.email,
                                        subject: "Contato para Zucco",
                                        html: {
                                            nome: objEmail.nome,
                                            tipo: "Consórcio",
                                            telefone: objEmail.telefone,
                                            email: objEmail.email,
                                            cpf: objEmail.cpf,
                                            valor: objEmail.valor
                                        },
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                      })

                                    setObjEmail({
                                        tipo: "",
                                        nome: "",
                                        cpf: "",
                                        email: "",
                                        telefone: "",
                                        valor: "",
                                    })

                                        const modal = document.getElementById('modal_consorcio') as HTMLDialogElement;;
                
                                        if (modal) {
                                          modal.close();
                                        } else {
                                          console.error("Element with ID 'modal_proposta' not found.");
                                        }
                                }} className="btn btn-primary text-white">Enviar proposta</button>
                        </div>                    
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    )
}