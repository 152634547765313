import { useEffect, useState } from "react";

import bgCobertura from '../assets/bgHomeCardSeguros.png'
import clima from '../assets/clima.png'
import incendio from '../assets/incendio.png'
import fatalidadeCobertura from '../assets/fatalidadeCobertura.png'
import eletrico from '../assets/eletrico.png'
import vidros from '../assets/vidros.png'
import impacto from '../assets/impacto.png'
import despesasFixas from '../assets/despesasFixas.png'
import greve from '../assets/greve.png'
import civil from '../assets/civil.png'

import { CardCobertura } from "../components/CardCobertura";
import { CardClientes } from "../components/CardClientes";
import { Propaganda } from "../components/Propaganda";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { listaClientes } from "../shared/utils/contantes";
import { postEmail } from "../service/api";

const Seguroempresarial: React.FC = () => {

    const lista = [
        {
            img: clima,
            titulo: "Problemas climaticos",
            texto: "A cobertura para problemas climáticos no seguro empresarial protege contra danos provocados por eventos naturais."
        },
        {
            img: incendio,
            titulo: "Perca por incêndio",
            texto: "A cobertura por incêndio no seguro empresarial garante indenização por perdas materiais causdas por incêndio."
        },
        {
            img: greve,
            titulo: "Greves e Lock-out",
            texto: "Oferece suporte financeiro em situações de paralisação devido a greves de funcionários ou bloqueio ao trabalho."
        },
        {
            img: civil,
            titulo: "Resposabilidade Civil Operações",
            texto: "Cobre danos causados a terceiros durante as atividades comerciais da empresa, indenizações e custos legais."
        },
        {
            img: fatalidadeCobertura,
            titulo: "Cobertura por Fatalidade",
            texto: "Beneficiários designados em caso de falecimento do segurado durante a vigência da apólice."
        },
        {
            img: eletrico,
            titulo: "Danos elétricos",
            texto: "A cobertura de danos elétricos protege seus aparelhos e instalações contra qualquer tipo de dano elétrico."
        },
        {
            img: vidros,
            titulo: "Vidros e Mármores",
            texto: "Indeniza danos acidentais a elementos como vidraças, espelhos e mármores dentro do imóvel segurado."
        },
        {
            img: despesasFixas,
            titulo: "Despesas Fixas",
            texto: "Cobre os custos operacionais essenciais, como salários e aluguel, em caso de interrupção das atividades comerciais."
        },
        {
            img: impacto,
            titulo: "Impacto de Veículos",
            texto: "Danos causados por carros ou veículos terceiros, como batidas em portões, muros a propriedade do segurado."
        },
    ]
    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        cnpj: "",
        ramo: "",
    })
    const [accordionState, setAccordionState] = useState(1);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
 
    return (
        <div>
            <div className="bg-bgDivSeguroEmpresa h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivSeguroEmpresaMobile bg-cover">
            {isMobile() ? null : (
                <div className="modal-box rounded-lg p-5 flex items-center flex-col bg-white">
                    <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                    <div className="w-full">
                    <div className="flex items-center w-full mt-8">
                        <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                        <hr className="w-full h-[2px] bg-primary" />
                        <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                    </div>
                    {etapa === 0 ? (
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full bg-white" />
                            <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                        </div>
                    ) : (
                        <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.cnpj} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cnpj: event.target.value
                                  }));
                            }} type="text" placeholder="CNPJ" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.ramo} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    ramo: event.target.value
                                  }));
                            }} type="text" placeholder="Ramo da empresa" className="input input-bordered w-full bg-white" />
                        <div className="w-full mt-5 grid grid-cols-2 gap-5">
                            <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                            <button onClick={() => {
                                postEmail({
                                    to: "vendas1@zuccoseguros.com.br",
                                    replyTo: objEmail.email,
                                    subject: "Contato para Zucco",
                                    html: {
                                        nome: objEmail.nome,
                                        tipo: "Seguro Empresarial",
                                        cpf: objEmail.cpf,
                                        telefone: objEmail.telefone,
                                        email: objEmail.email,
                                        cnpj: objEmail.cnpj,
                                        ramo: objEmail.ramo,
                                    },
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                  })

                                setObjEmail({
                                    tipo: "",
                                    nome: "",
                                    cpf: "",
                                    email: "",
                                    telefone: "",
                                    cnpj: "",
                                    ramo: "",
                                })
                                setEtapa(0)
                            }} className="btn btn-primary text-white">Enviar Proposta</button>
                        </div>
                    </div>
                    )}
                    </div>
                </div>
            )}
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14">Aqui alguma das coberturas para <span className="text-primary">você e sua empresa</span></h3>
            <img src={bgCobertura} alt="Nossa história" className="mobile:h-60 mobile:object-cover" />
            <CardCobertura lista={lista} />
            
            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:mt-14 mobile:text-base">Saiba o que <span className="text-primary">nossos clientes falam</span></h3>
            <CardClientes lista={listaClientes} />

            <Propaganda />

            <div className="px-48 mt-24 mb-40 mobile:px-3 mobile:mb-20">
                <h2 className="text-2xl font-semibold mobile:text-base">Dúvidas sobre o <span className="text-primary">Seguro de Vida?</span></h2>
                <div className="flex flex-col gap-1 mt-3 mobile:mt-0">
                    <div onClick={() => setAccordionState(1)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 1}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Qual cobertura do Seguro Empresa é ideal para meu negócio?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">
                            A cobertura ideal para o seguro empresarial depende das características do seu negócio. Coberturas comuns incluem proteção contra incêndio, roubo, danos elétricos, responsabilidade civil, entre outras. Avalie os riscos específicos do seu ramo de atividade, localização e necessidades do negócio. Por exemplo, se trabalha com equipamentos caros, uma cobertura contra danos a maquinário pode ser crucial. Se há interação direta com clientes, a responsabilidade civil pode ser fundamental. Consultar um corretor de seguros para avaliar as necessidades específicas do seu negócio é uma ótima maneira de identificar as coberturas mais adequadas.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(2)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 2}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Como acionar os serviços de assistência do seguro empresarial?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">
                            Para acionar os serviços de assistência do seguro empresarial, geralmente, é necessário entrar em contato com a seguradora por meio dos canais disponíveis, como telefone, e-mail ou aplicativo. Ao acionar a assistência, forneça todas as informações necessárias, como número da apólice, detalhes do problema ou emergência e localização da empresa. A seguradora orientará sobre os procedimentos a serem seguidos, enviará profissionais especializados para resolver a situação emergencial, como eletricistas, encanadores, entre outros, conforme as coberturas contratadas. É essencial estar familiarizado com os procedimentos de acionamento, que variam de acordo com a seguradora e as coberturas do seguro empresarial contratado.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(3)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 3}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Por que contratar um seguro de empresa?</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">Contratar um seguro empresarial oferece uma série de benefícios importantes para proteger o negócio. Ele ajuda a minimizar os impactos financeiros de imprevistos, como incêndios, roubos, danos elétricos, entre outros eventos que possam prejudicar a empresa. Além disso, a cobertura de responsabilidade civil pode proteger a empresa de possíveis processos judiciais. O seguro também pode oferecer assistências específicas para resolver emergências no local de trabalho, como problemas elétricos, hidráulicos e até mesmo suporte em caso de necessidade de reparos. Em resumo, o seguro empresarial proporciona segurança financeira e suporte operacional para o negócio em situações imprevisíveis, garantindo maior tranquilidade para os proprietários e gestores.</p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                </div>
            </div>
        </div>
    )
}

export default Seguroempresarial;