import { Routes as RoutesDOM, Route } from "react-router-dom";

import Home from "../pages/home";
import { HomeAdmin } from "../pages/Admin/home";
import SobreNos from "../pages/sobreNos";
import SeguroAuto from "../pages/seguroAuto";
import Seguroempresarial from "../pages/seguroEmpresarial";
import SeguroVida from "../pages/seguroVida";
import SeguroResidencial from "../pages/seguroResidencial";
import { Asseguradoras } from "../pages/asseguradoras";
import { ConsorcioAuto } from "../pages/consorcioAuto";
import { ConsorcioImobiliario } from "../pages/consorcioImobiliario";
import { Login } from "../pages/Admin/login";
import { VisitasAdmin } from "../pages/Admin/visitas";
import { WhatsappAdmin } from "../pages/Admin/whatsapp";
import { Configuracoes } from "../pages/Admin/configuracao";
import { Banner } from "../pages/Admin/banner";

const Routes = () => {
  return (
    <RoutesDOM>
      {/* <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<PageNotFound />} /> */}
      <Route path="/" element={<Home />}/>
      <Route path="/sobre-nos" element={<SobreNos />}/>
      <Route path="/seguro-auto" element={<SeguroAuto />}/>
      <Route path="/seguro-empresa" element={<Seguroempresarial />}/>
      <Route path="/seguro-vida" element={<SeguroVida />}/>
      <Route path="/seguro-residencial" element={<SeguroResidencial />}/>
      <Route path="/asseguradoras" element={<Asseguradoras />}/>
      <Route path="/consorcio-auto" element={<ConsorcioAuto />}/>
      <Route path="/consorcio-imobiliario" element={<ConsorcioImobiliario />}/>

      <Route path="/admin" element={<Login />}/>
      <Route path="/admin/home" element={<HomeAdmin />}/>
      <Route path="/admin/visitas" element={<VisitasAdmin />}/>
      <Route path="/admin/whatsapp" element={<WhatsappAdmin />}/>
      <Route path="/admin/configuracoes" element={<Configuracoes />}/>
      <Route path="/admin/banner" element={<Banner />}/>
    </RoutesDOM>
  );
};

export default Routes;
