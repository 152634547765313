export const listaClientes = [
    {
        titulo: "Gustavo",
        texto: "Os profissionais que me atenderam, me auxiliaram em qual seguro era melhor para mim e minha família, com toda certeza sou grato ao cuidado e carinho que tiveram comigo! Recomendo!",
        data: "Agosto de 2021"
    },
    {
        titulo: "Elaine",
        texto: "Atendentes sempre prontos para suprir as necessidades em matéria de seguros. Recomendo muito a Zucco Seguros!",
        data: "Maio de 2020"
    },
    {
        titulo: "Rodrigo",
        texto: "Atendimento personalizado! Credibilidade! ✅ Com certeza o melhor da região, me ajudaram em diversos momentos que precisei!",
        data: "Janeiro de 2018"
    },
    {
        titulo: "João",
        texto: "Ótimo profissional! Estou muito satisfeito e segurado! 👏🏻👏🏻👏🏻 Já recorri algumas vezes em situações que precisei e me auxiliaram da melhor maneira!",
        data: "Dezembro de 2022"
    },
    {
        titulo: "Carol",
        texto: "Super competentes. Recomendo 😍, estou com vocês a anos e não me arrependo de nada!",
        data: "Junho de 2021"
    },
]