import { useNavigate } from 'react-router-dom'

export const AsideAdmin = () => {
    const navigate = useNavigate()
    return (
        <div className='max-w-20 h-screen z-20'>
            <ul className="menu bg-base-300 h-full gap-5 pt-5">
                <li onClick={() => {
                        navigate('/admin/home')
                    }}>
                    <a className="tooltip tooltip-right" data-tip="Home">
                        <i className="ri-home-3-line ri-xl text-white"></i>
                    </a>
                </li>
                <li onClick={() => {
                    navigate('/admin/visitas')
                }}>
                    <a className="tooltip tooltip-right" data-tip="Visitas">
                        <i className="ri-line-chart-line ri-xl text-white"></i>
                    </a>
                </li>
                <li onClick={() => {
                    navigate('/admin/banner')
                }}>
                    <a className="tooltip tooltip-right" data-tip="Banner">
                        <i className="ri-layout-column-fill ri-xl text-white"></i>
                    </a>
                </li>
                <li onClick={() => {
                    window.open('/admin/configuracoes', "_self")
                }}>
                    <a className="tooltip tooltip-right" data-tip="Configurações">
                        <i className="ri-tools-line ri-xl text-white"></i>
                    </a>
                </li>
                <li onClick={() => {
                    navigate('/admin/whatsapp')
                }}>
                    <a className="tooltip tooltip-right" data-tip="Whatsapp">
                        <i className="ri-whatsapp-line ri-xl text-white"></i>
                    </a>
                </li>
            </ul>
        </div>
    )
}