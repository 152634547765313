import bgSobreNos from '../assets/bgNossaHistoriaPage.png'
import bgSobreNosMobile from '../assets/bgSobreNosMobile.png'
import bgSobreNosTexto from '../assets/backgroundTextoNossaHistoria.png'
import fundadores from '../assets/fundadores.png'
import equipe from '../assets/equipe.png'
import bgComunicacao from '../assets/comunicacao.png'
import bgSobreNosMobilePage from '../assets/bgSobreNosMobilePagina.jpg'
import bgComunicacaoMobile from '../assets/bgMeioComunicacaoMobile.png'
import { useEffect, useRef, useState } from 'react'
import { isMobile, scrollToTop } from '../shared/utils/javascript'
import { getInfosLoja, postEmail } from '../service/api'
import { InfosLoja } from '../shared/types'

const SobreNos: React.FC = () => {

    const [infosLojaResult, setInfosLojaResult] = useState<InfosLoja>()

    const [dataEmail, setDataEmail] = useState({
        nome: "",
        telefone: "",
        email: "",
        cidade: "",
        mensagem: ""
    })

    const handleSubmit = () => {
        postEmail({
            to: "vendas1@zuccoseguros.com.br",
            replyTo: dataEmail.email,
            subject: "Contato para Zucco",
            html: {
                nome: dataEmail.nome,
                telefone: dataEmail.telefone,
                email: dataEmail.email,
                cidade: dataEmail.cidade,
                mensagem: dataEmail.mensagem
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
        setDataEmail({
            nome: "",
            telefone: "",
            email: "",
            cidade: "",
            mensagem: ""
        })
    }

    const infosLoja = async () => {
        try {
            const result = await getInfosLoja();
            setInfosLojaResult(result?.data)
        } catch {

        }
    }

    useEffect(() => {
        infosLoja()
    },[])

    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
          const scrollContainer = scrollContainerRef.current;
    
          // Largura total do conteúdo
          const contentWidth = scrollContainer.scrollWidth;
    
          // Largura visível do contêiner
          const containerWidth = scrollContainer.clientWidth;
    
          // Defina a posição inicial do scroll para o meio
          scrollContainer.scrollLeft = (contentWidth - containerWidth) / 2;
        }
      }, []);

      useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div>
            {isMobile() ? (
                <img className='w-full' src={bgSobreNosMobile} alt="Nossa história" />
            ) : (
                <img src={bgSobreNos} alt="Nossa história" />
            )}
            <div className="grid grid-cols-3 gap-24 mt-[-70px] px-48 mobile:overflow-x-auto mobile:flex mobile:gap-2 mobile:pb-3 mobile:px-3" ref={scrollContainerRef}>
                <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                    <h1 className="font-slab text-5xl mobile:text-3xl text-center">+20 Anos</h1>
                    <h4 className="leading-tight font-medium text-center">A mais de 20 anos no mercado trazendo segurança a nossos clientes</h4>
                </div>
                <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                    <h1 className="font-slab text-5xl mobile:text-3xl text-center">+3.000 pessoas</h1>
                    <h4 className="leading-tight font-medium text-center">Mais de 3 mil pessoas que passaram por nossa empresa confiam em nosso trabalho.</h4>
                </div>
                <div className="bg-white flex flex-col justify-center items-center rounded-lg p-5 gap-4 shadow-shadow mobile:p-3 mobile:min-w-[80vw]">
                    <h1 className="font-slab text-5xl mobile:text-3xl text-center">+10 parceiros</h1>
                    <h4 className="leading-tight font-medium text-center">AS principais Seguradoras do páis confiam em nosso trabalho.</h4>
                </div>
            </div>

            <img src={isMobile() ? bgSobreNosMobilePage : bgSobreNosTexto} alt="Nossa história" className='mt-36 mobile:mt-24 mobile:w-full' />
            <div className="grid grid-cols-2 gap-x-24 gap-y-12 mt-[-1220px] px-48 mobile:px-3 mobile:flex-col mobile:mt-[-1890px] mobile:gap-y-3 mobile:flex">
                <div className='flex flex-col gap-10'>
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 23.44%)"}} className="bg-white flex flex-col justify-center rounded-lg p-5 gap-4 shadow-shadow w-full h-min mobile:w-full">
                        <div className="font-medium flex gap-3 text-base items-center mobile:text-sm">
                            <i className="ri-git-repository-line ri-lg"></i>
                            <h4>A História da Zucco Seguros</h4>
                        </div>
                        <h4 className="leading-tight font-medium text-justify mobile:text-xs"><div dangerouslySetInnerHTML={{ __html: infosLojaResult?.loja.tbl_infosLoja[0].descricao ?? "" }} /></h4>
                    </div>
                    <div className='w-full mobile:w-full'>
                        <img src={isMobile() ? equipe : fundadores} alt="Equipe" />
                    </div>
                </div>
                {isMobile() ? (
                    <>
                        <div className="bg-white flex flex-col justify-center rounded-lg p-5 gap-4 shadow-shadow w-[46%] h-min mt-5 mobile:mt-0 mobile:w-full">
                            <div className="font-medium flex gap-3 text-base items-center mobile:text-sm">
                                <i className="ri-building-2-line ri-lg"></i>
                                <h4>A nossa visão para o futuro da empresa</h4>
                            </div>                    
                            <h4 className="leading-tight font-medium text-justify mobile:text-xs">A nossa visão para o futuro da Zucco Corretora de Seguros é marcada por uma busca incessante pela inovação, qualidade e excelência em serviços. Com mais de duas décadas de trajetória, almejamos consolidar ainda mais nossa posição como líderes no mercado de seguros, elevando continuamente os padrões de confiabilidade e satisfação do cliente.

                            Antevemos uma empresa que se destaca não apenas pela diversidade de serviços oferecidos, mas pela sua capacidade de adaptação às demandas emergentes do mercado. Estamos comprometidos em manter-nos à frente das tendências, adotando proativamente as tecnologias mais avançadas para aprimorar a eficiência operacional e proporcionar aos nossos clientes uma experiência ainda mais ágil, intuitiva e personalizada.

                            A expansão de parcerias estratégicas com seguradoras de renome continuará a ser uma pedra angular da nossa estratégia de negócios. Planejamos fortalecer essas alianças, garantindo que nossos clientes se beneficiem das melhores opções de cobertura, respaldadas pela solidez financeira e confiabilidade das empresas parceiras.

                            Além disso, enxergamos a Zucco Seguros como uma força motriz na conscientização e educação sobre seguros. Planejamos oferecer recursos informativos e educacionais para capacitar nossos clientes a tomar decisões mais informadas sobre suas apólices, promovendo um entendimento mais profundo sobre os benefícios e a importância do seguro em suas vidas.

                            Em resumo, vislumbramos a Zucco Corretora de Seguros como uma empresa que transcende as expectativas, continuamente inovadora, focada na satisfação do cliente e comprometida com os mais altos padrões éticos e de transparência. Nosso futuro será moldado pela nossa dedicação implacável à excelência, estabelecendo-nos como verdadeiros parceiros de confiança para nossos clientes, à medida que avançamos para novos horizontes de sucesso e crescimento.
                            </h4>
                        </div>
                        <div className='w-[46%] mt-[-75px] mobile:w-full mobile:mt-0'>
                            <img src={fundadores} alt="Fundadores" />
                        </div>
                    </>
                ) : (
                    <div className='flex flex-col gap-10'>
                        <div className='w-full mobile:w-full mobile:mt-0'>
                            <img src={equipe} alt="Fundadores" />
                        </div>
                        <div className="bg-white flex flex-col justify-center rounded-lg p-5 gap-4 shadow-shadow w-full h-min">
                            <div className="font-medium flex gap-3 text-base items-center mobile:text-sm">
                                <i className="ri-building-2-line ri-lg"></i>
                                <h4>A nossa visão para o futuro da empresa</h4>
                            </div>                    
                            <h4 className="leading-tight font-medium text-justify mobile:text-xs">A nossa visão para o futuro da Zucco Corretora de Seguros é marcada por uma busca incessante pela inovação, qualidade e excelência em serviços. Com mais de duas décadas de trajetória, almejamos consolidar ainda mais nossa posição como líderes no mercado de seguros, elevando continuamente os padrões de confiabilidade e satisfação do cliente.

                            Antevemos uma empresa que se destaca não apenas pela diversidade de serviços oferecidos, mas pela sua capacidade de adaptação às demandas emergentes do mercado. Estamos comprometidos em manter-nos à frente das tendências, adotando proativamente as tecnologias mais avançadas para aprimorar a eficiência operacional e proporcionar aos nossos clientes uma experiência ainda mais ágil, intuitiva e personalizada.

                            A expansão de parcerias estratégicas com seguradoras de renome continuará a ser uma pedra angular da nossa estratégia de negócios. Planejamos fortalecer essas alianças, garantindo que nossos clientes se beneficiem das melhores opções de cobertura, respaldadas pela solidez financeira e confiabilidade das empresas parceiras.

                            Além disso, enxergamos a Zucco Seguros como uma força motriz na conscientização e educação sobre seguros. Planejamos oferecer recursos informativos e educacionais para capacitar nossos clientes a tomar decisões mais informadas sobre suas apólices, promovendo um entendimento mais profundo sobre os benefícios e a importância do seguro em suas vidas.

                            Em resumo, vislumbramos a Zucco Corretora de Seguros como uma empresa que transcende as expectativas, continuamente inovadora, focada na satisfação do cliente e comprometida com os mais altos padrões éticos e de transparência. Nosso futuro será moldado pela nossa dedicação implacável à excelência, estabelecendo-nos como verdadeiros parceiros de confiança para nossos clientes, à medida que avançamos para novos horizontes de sucesso e crescimento.
                            </h4>
                        </div>
                    </div>
                )}
            </div>

            <div className='px-48 mt-24 mobile:px-0 mobile:mt-12'>
                <h3 className='text-2xl font-medium mobile:px-3'>Conheça os <span className='text-primary'>nossos valores</span></h3>
                <div className='flex flex-row gap-12 mt-12 mobile:mt-5 mobile:overflow-x-auto pb-3 mobile:gap-3'>
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 32.96%)"}} className='p-5 items-center flex flex-col rounded-lg shadow-shadow mobile:min-w-[85vw] mobile:p-3 mobile:h-auto'>
                        <i className={`ri-flag-line ${isMobile() ? "ri-2x" : "ri-4x"}`}></i>
                        <h2 className='text-2xl font-medium mobile:text-sm'>Missão</h2>
                        <h3 className='text-center font-medium mt-5 mobile:text-xs mobile:mt-2'>Nosso compromisso é proteger o que é mais importante para você. Buscamos oferecer segurança e tranquilidade através de soluções inovadoras e confiáveis em seguros, visando sempre a satisfação e a proteção integral de nossos clientes.</h3>
                    </div>
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 32.96%)"}} className='p-5 items-center flex flex-col rounded-lg shadow-shadow mobile:min-w-[85vw] mobile:p-3 mobile:h-auto'>
                        <i className={`ri-eye-line ${isMobile() ? "ri-2x" : "ri-4x"}`}></i>
                        <h2 className='text-2xl font-medium mobile:text-sm'>Visão</h2>
                        <h3 className='text-center font-medium mt-5 mobile:text-xs mobile:mt-2'>Nossa visão é ser referência em proteção e confiança, superando expectativas ao oferecer soluções inovadoras e adaptáveis, garantindo a segurança e o bem-estar de nossos clientes em todos os momentos.</h3>
                    </div>
                    <div style={{background: "linear-gradient(180deg, #F1F1F1 0%, #FFF 32.96%)"}} className='p-5 items-center flex flex-col rounded-lg shadow-shadow mobile:min-w-[85vw] mobile:p-3 mobile:h-auto'>
                        <i className={`ri-heart-2-line ${isMobile() ? "ri-2x" : "ri-4x"}`}></i>
                        <h2 className='text-2xl font-medium mobile:text-sm'>Valores</h2>
                        <h3 className='text-center font-medium mt-5 mobile:text-xs mobile:mt-2'>Nossos valores fundamentais incluem integridade, excelência no serviço, comprometimento com a segurança, inovação constante e foco no cliente, buscando sempre a confiança e a proteção em cada interação.</h3>
                    </div>
                </div>
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14 mobile:mb-[-5px]">Nossos meios de <span className="text-primary">comunicação</span></h3>
                <img src={isMobile() ? bgComunicacaoMobile : bgComunicacao} alt="Meio de comunicação" className='mobile:w-full' />
                <div className="px-48 mt-[-280px] flex gap-12 mobile:px-3 mobile:gap-4 mobile:flex-col mobile:mt-[-890px]">
                    <div className='w-2/3 bg-white rounded-lg flex flex-row shadow-shadow mobile:flex-col mobile:w-full'>
                        <div className='p-10 w-1/2 mobile:w-full mobile:px-3 mobile:py-5'>
                            <div>
                                <h2 className='text-2xl font-medium mobile:text-lg'>Nos envie um e-mail</h2>
                            </div>
                            <div className=' mt-5 flex flex-col gap-5 mobile:gap-3'>
                                <input value={dataEmail.nome} onChange={(event) => {
                                    setDataEmail(prevState => ({
                                        ...prevState,
                                        nome: event.target.value
                                    }));
                                }} type="text" placeholder="Nome" className="input input-bordered w-full bg-white rounded-xl" />
                                <input value={dataEmail.telefone} onChange={(event) => {
                                    setDataEmail(prevState => ({
                                        ...prevState,
                                        telefone: event.target.value
                                    }));
                                }} type="text" placeholder="Telefone" className="input input-bordered w-full bg-white rounded-xl" />
                                <input value={dataEmail.email} onChange={(event) => {
                                    setDataEmail(prevState => ({
                                        ...prevState,
                                        email: event.target.value
                                    }));
                                }} type="text" placeholder="E-mail" className="input input-bordered w-full bg-white rounded-xl" />
                                <input value={dataEmail.cidade} onChange={(event) => {
                                    setDataEmail(prevState => ({
                                        ...prevState,
                                        cidade: event.target.value
                                    }));
                                }} type="text" placeholder="Cidade" className="input input-bordered w-full bg-white rounded-xl" />
                                <textarea value={dataEmail.mensagem} onChange={(event) => {
                                    setDataEmail(prevState => ({
                                        ...prevState,
                                        mensagem: event.target.value
                                    }));
                                }} placeholder="Mensagem" className="textarea textarea-bordered w-full bg-white rounded-xl min-h-32" />
                                <button onClick={handleSubmit} className='btn btn-base-200 font-medium text-sm text-white h-10 min-h-min' type="submit">Enviar mensagem</button>
                            </div>
                        </div>
                        {isMobile() ? null : (
                            <div className='w-1/2'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1776.0182832872988!2d-48.92018126742602!3d-27.092142913892555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df479bdedbd42f%3A0x67ffa6a36847c8fa!2sZucco%20Corretora%20de%20Seguros!5e0!3m2!1spt-BR!2sbr!4v1705423313188!5m2!1spt-BR!2sbr" width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        )}
                    </div>
                    <div className=' bg-white rounded-lg p-10 shadow-shadow flex flex-col justify-between mobile:px-3 mobile:py-5'>
                        <h2 className='text-2xl font-medium mobile:text-base'>Ou se preferir, entre em contato utilizando:</h2>
                        <div className='text-base font-medium mobile:mt-5'>
                            <h3 className='mobile:text-sm'>Números</h3>
                            <h3 onClick={() => {
                                window.open("https://api.whatsapp.com/send?phone=+5547991291002&text=Olá, Zucco Seguros!")
                            }} className='mt-2 cursor-pointer mobile:text-xs'><i className="ri-whatsapp-line"></i> (47) 99129-1002</h3>
                            <h3 onClick={() => {
                                window.open("tel:+55(47) 3351-9899")
                            }} className='mt-2 cursor-pointer mobile:text-xs'><i className="ri-phone-line"></i> (47) 3351-9899</h3>
                        </div>
                        <div className='text-base font-medium mobile:mt-5'>
                            <h3 className='mobile:text-sm'>E-mails</h3>
                            <h3 onClick={() => {
                                window.open("mailto:vendas1@zuccoseguros.com.br")
                            }} className='mt-2 cursor-pointer mobile:text-xs'><i className="ri-mail-send-line"></i> vendas1@zuccoseguros.com.br</h3>
                        </div>
                        <div className='text-base font-medium mobile:mt-5'>
                            <h3 className='mobile:text-sm'>Localização</h3>
                            <div className='flex items-center gap-1'>
                                <i className="ri-map-2-line"></i>
                                <h3 className='mt-2 mobile:text-xs'>
                                    R. João Bauer, <span className="break-line">444</span><br />
                                    Centro 1, Brusque - SC
                                </h3>
                            </div>
                        </div>
                        <div className='text-base font-medium mobile:mt-5'>
                            <h3 className='mobile:text-sm'>Redes sociais</h3>
                            <div className='flex gap-5'>
                                <h3 onClick={() => {
                                    window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlFacebook, "_blank")
                                }} className='mt-2 cursor-pointer'><i className="ri-facebook-circle-fill ri-xl"></i></h3>
                                <h3 onClick={() => {
                                    window.open(infosLojaResult?.loja.tbl_infosLoja[0].urlInstagram, "_blank")
                                }} className='mt-2 cursor-pointer'><i className="ri-instagram-line ri-xl"></i></h3>
                                <h3 onClick={() => {
                                    window.open("https://br.linkedin.com/in/cleandro-luis-zucco-824486279", "_blank")
                                }} className='mt-2 cursor-pointer'><i className="ri-linkedin-box-fill ri-xl"></i></h3>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    )
}

export default SobreNos;