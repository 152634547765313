import { useEffect, useState } from "react";

import bgCobertura from '../assets/bgHomeCardSeguros.png'
import eletrico from '../assets/eletrico.png'
import furto from '../assets/furto.png'
import clima from '../assets/clima.png'
import incendio from '../assets/incendio.png'
import responsabilidade from '../assets/responsabilidade.png'
import impacto from '../assets/impacto.png'
import vidros from '../assets/vidros.png'
import bicicletas from '../assets/bicicletas.png'
import vazamentos from '../assets/vazamento.png'
import despesas from '../assets/despesas.png'
import portao from '../assets/portao.png'

import { CardCobertura } from "../components/CardCobertura";
import { CardClientes } from "../components/CardClientes";
import { Propaganda } from "../components/Propaganda";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { listaClientes } from "../shared/utils/contantes";
import { postEmail } from "../service/api";

const SeguroResidencial: React.FC = () => {

    const lista = [
        {
            img: eletrico,
            titulo: "Danos elétricos",
            texto: "A cobertura de danos elétricos protege seus aparelhos e instalações contra qualquer tipo de dano elétrico."
        },
        {
            img: furto,
            titulo: "Roubo e furto de bens",
            texto: "A cobertura contra roubo e furto no seguro residencial assegura a indenização por perdas de bens."
        },
        {
            img: clima,
            titulo: "Problemas climaticos",
            texto: "A cobertura para problemas climáticos protege sua residência contra danos causados por eventos naturais."
        },
        {
            img: incendio,
            titulo: "Perca por incêndio",
            texto: "A cobertura por incêndio no seguro residencial garante indenização por perdas materiais causdas por incêndio."
        },
        {
            img: responsabilidade,
            titulo: "Responsabilidade a Danos",
            texto: "Cobre danos involuntários causados a terceiros no ambiente residencial do segurado."
        },
        {
            img: impacto,
            titulo: "Impacto de Veículos",
            texto: "Danos causados por carros ou veículos terceiros, como batidas em portões, muros a propriedade do segurado."
        },
        {
            img: vidros,
            titulo: "Vidros e Mármores",
            texto: "Indeniza danos acidentais a elementos como vidraças, espelhos e mármores dentro do imóvel segurado."
        },
        {
            img: bicicletas,
            titulo: "Subtração de Bicicleta",
            texto: "Oferece indenização no caso de roubo ou furto da bicicleta dentro da residência segurada."
        },
        {
            img: vazamentos,
            titulo: "Vazamentos e tubulações",
            texto: "Ampara danos causados por vazamentos internos, rupturas ou problemas nas tubulações de água."
        },
        {
            img: despesas,
            titulo: "Indenização a despesas",
            texto: "Proporciona indenização ou auxílio financeiro para despesas de aluguel caso o imóvel torne-se inabitável."
        },
        {
            img: portao,
            titulo: "RC Portões Automáticos",
            texto: "Abrange danos causados a terceiros pelo funcionamento ou falhas no portão automático do imóvel segurado."
        },
    ]
    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        dataNascimento: "",
        cidade: "",
    })
    const [accordionState, setAccordionState] = useState(1);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
 
    return (
        <div>
            <div className="bg-bgDivSeguroResidencial h-[600px] flex items-center justify-end px-48 mobile:bg-bgDivSeguroResidencialMobile bg-cover">
            {isMobile() ? null : (
                <div className="modal-box rounded-lg p-5 flex items-center flex-col bg-white">
                    <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                    <div className="w-full">
                    <div className="flex items-center w-full mt-8">
                        <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                        <hr className="w-full h-[2px] bg-primary" />
                        <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                    </div>
                    {etapa === 0 ? (
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full bg-white" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full bg-white" />
                            <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                        </div>
                    ) : (
                        <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full bg-white" />
                        <input value={objEmail.cidade} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cidade: event.target.value
                                  }));
                            }} type="text" placeholder="Cidade" className="input input-bordered w-full bg-white" />
                        <div className="w-full mt-5 grid grid-cols-2 gap-5">
                            <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                            <button onClick={() => {
                                postEmail({
                                    to: "vendas1@zuccoseguros.com.br",
                                    replyTo: objEmail.email,
                                    subject: "Contato para Zucco",
                                    html: {
                                        nome: objEmail.nome,
                                        tipo: "Seguro Residêncial",
                                        cpf: objEmail.cpf,
                                        telefone: objEmail.telefone,
                                        email: objEmail.email,
                                        cidade: objEmail.cidade,
                                        dataNascimento: objEmail.dataNascimento,
                                    },
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                  })

                                setObjEmail({
                                    tipo: "",
                                    nome: "",
                                    cpf: "",
                                    email: "",
                                    telefone: "",
                                    dataNascimento: "",
                                    cidade: "",
                                })
                                setEtapa(0)
                            }} className="btn btn-primary text-white">Enviar Proposta</button>
                        </div>
                    </div>
                    )}
                    </div>
                </div>
                )}
            </div>

            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:text-base mobile:mt-14">Aqui alguma das coberturas para <span className="text-primary">você e sua residência</span></h3>
            <img src={bgCobertura} alt="Nossa história" className="mobile:h-60 mobile:object-cover" />
            <CardCobertura lista={lista} />
            
            <h3 className="font-semibold text-2xl px-48 leading-[16px] mt-24 mobile:px-3 mobile:mt-14 mobile:text-base">Saiba o que <span className="text-primary">nossos clientes falam</span></h3>
            <CardClientes lista={listaClientes} />

            <Propaganda />

            <div className="px-48 mt-24 mb-40 mobile:px-3 mobile:mb-20">
                <h2 className="font-semibold text-2xl mobile:text-base">Dúvidas sobre o <span className="text-primary">Seguro Residêncial?</span></h2>
                <div className="flex flex-col gap-1 mt-3 mobile:mt-0">
                    <div onClick={() => setAccordionState(1)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 1}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>O que o seguro residencial cobre?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col mobile:text-xs"> 
                            <p className="text-justify font-medium leading-tight">
                                O seguro residencial cobre uma ampla gama de situações, desde eventos comuns até imprevistos maiores. Inclui danos por incêndios, explosões, alagamentos, problemas elétricos, quebra de vidros, danos de reformas, responsabilidade civil, proteção a itens de negócios em casa e roubo/furto, oferecendo mais de 20 opções de cobertura personalizáveis para atender às suas necessidades específicas.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(2)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 2}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Por que contratar um seguro residencial?</h4>
                        </div>
                        <div className="collapse-content gap-6 flex flex-col"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">
                                Com certeza, o seguro residencial é essencial para preservar as conquistas ao longo dos anos. Com as coberturas e indenizações contratadas, oferece a reposição do que foi danificado ou perdido em sinistros. Além de assegurar o patrimônio, é uma proteção financeira valiosa contra imprevistos.
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                    <div onClick={() => setAccordionState(3)} className="collapse collapse-arrow bg-white">
                        <input
                            type="radio"
                            name="my-accordion-1"
                            checked={accordionState === 3}
                        />
                        <div className="collapse-title text-xl font-medium flex gap-3 mobile:text-sm">
                            <h4>Qual é o melhor plano do seguro residencial?</h4>
                        </div>
                        <div className="collapse-content"> 
                            <p className="text-justify font-medium leading-tight mobile:text-xs">O plano ideal de seguro residencial varia de acordo com cada situação. Cada residência, localização e morador têm necessidades específicas que demandam diferentes coberturas e serviços. Um corretor de seguros é fundamental para orientar na escolha do plano que melhor se adapta à sua vida e às suas necessidades.</p>
                        </div>
                    </div>
                    <div className="border-b border-neutral-content mx-3" />
                </div>
            </div>
        </div>
    )
}

export default SeguroResidencial;