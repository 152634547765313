import { useState } from "react"
import { postEmail } from "../service/api";

export const ModalProposta = () => {

    const [etapa, setEtapa] = useState(0)
    const [objEmail, setObjEmail] = useState({
        tipo: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        dataNascimento: "",
        placaCarro: "",
        marca: "",
        modelo: "",
        cidade: "",
        profissao: "",
        cnpj: "",
        ramoEmpresa: ""
    })

    const returnSegundaEtapa = () => {
        switch (objEmail.tipo) {
            case "seguro-auto":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.placaCarro} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    placaCarro: event.target.value
                                  }));
                            }} type="text" placeholder="Placa do carro" className="input input-bordered w-full" />
                        <input value={objEmail.marca} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    marca: event.target.value
                                  }));
                            }} type="text" placeholder="Marca" className="input input-bordered w-full" />
                        <input value={objEmail.modelo} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    modelo: event.target.value
                                  }));
                            }} type="text" placeholder="Modelo" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-residencial":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.cidade} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cidade: event.target.value
                                  }));
                            }} type="text" placeholder="Cidade" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-vida":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.dataNascimento} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    dataNascimento: event.target.value
                                  }));
                            }} type="text" placeholder="Data de nascimento" className="input input-bordered w-full" />
                        <input value={objEmail.profissao} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    profissao: event.target.value
                                  }));
                            }} type="text" placeholder="Profissão" className="input input-bordered w-full" />
                    </div>
                );
            case "seguro-empresa":
                return (
                    <div className="w-full mt-5 flex flex-col gap-5">
                        <input value={objEmail.cnpj} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cnpj: event.target.value
                                  }));
                            }} type="text" placeholder="CNPJ" className="input input-bordered w-full" />
                        <input value={objEmail.ramoEmpresa} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    ramoEmpresa: event.target.value
                                  }));
                            }} type="text" placeholder="Ramo da empresa" className="input input-bordered w-full" />
                    </div>
                );
            default:
                return null;
        }
    };
    
    
    return (
        <dialog id="modal_proposta" className="modal">
            <div className="modal-box rounded-lg p-5 flex items-center flex-col">
                <h3 className="text-2xl font-medium flex gap-2">Solicite aqui <h3 className="text-primary font-semibold">sua proposta</h3></h3>
                <div className="w-full">
                    <div className="flex items-center w-full mt-8">
                        <h3 className={`rounded-full text-white px-[15px] py-[5px] ${etapa === 0 ? "bg-primary" : "bg-neutral"}`}>1</h3>
                        <hr className="w-full h-[2px] bg-primary" />
                        <h3 className={`rounded-full text-white px-[13px] py-[5px] ${etapa === 1 ? "bg-primary" : "bg-neutral"}`}>2</h3>
                    </div>
                    {etapa === 0 ? (
                        <div className="w-full mt-5 flex flex-col gap-5">
                            <select value={objEmail.tipo} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    tipo: event.target.value
                                  }));
                            }} className="select select-bordered w-full text-black font-medium">
                                <option value="" disabled selected>Qual o seguro?</option>
                                <option value="seguro-auto">Seguro Auto</option>
                                <option value="seguro-residencial">Seguro Residencial</option>
                                <option value="seguro-vida">Seguro de Vida</option>
                                <option value="seguro-empresa">Seguro Empresa</option>
                            </select>
                            <input value={objEmail.nome} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    nome: event.target.value
                                  }));
                            }} type="text" placeholder="Nome" className="input input-bordered w-full" />
                            <input value={objEmail.cpf} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    cpf: event.target.value
                                  }));
                            }} type="text" placeholder="CPF" className="input input-bordered w-full" />
                            <input value={objEmail.email} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    email: event.target.value
                                  }));
                            }} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                            <input value={objEmail.telefone} onChange={(event) => {
                                setObjEmail(prevState => ({
                                    ...prevState,
                                    telefone: event.target.value
                                  }));
                            }} type="text" placeholder="Telefone" className="input input-bordered w-full" />
                            <button type="button" onClick={() => setEtapa(1)} className="btn btn-primary text-white">Próxima etapa</button>
                        </div>
                    ) : (
                        <div>
                            {returnSegundaEtapa()}
                            <div className="w-full mt-5 grid grid-cols-2 gap-5">
                                <button onClick={() => setEtapa(0)} className="btn btn-outline btn-primary">Voltar</button>
                                <button onClick={() => {
                                    postEmail({
                                        to: "vendas1@zuccoseguros.com.br",
                                        replyTo: objEmail.email,
                                        subject: "Contato para Zucco",
                                        html: {
                                            nome: objEmail.nome,
                                            tipo: objEmail.tipo,
                                            cpf: objEmail.cpf,
                                            telefone: objEmail.telefone,
                                            email: objEmail.email,
                                            dataNascimento: objEmail.dataNascimento,
                                            placaCarro: objEmail.placaCarro,
                                            marca: objEmail.marca,
                                            modelo: objEmail.modelo,
                                            cidade: objEmail.cidade,
                                            profissao: objEmail.profissao,
                                            cnpj: objEmail.cnpj,
                                            ramoEmpresa: objEmail.ramoEmpresa,
                                        },
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                      })

                                    setObjEmail({
                                        tipo: "",
                                        nome: "",
                                        cpf: "",
                                        email: "",
                                        telefone: "",
                                        dataNascimento: "",
                                        placaCarro: "",
                                        marca: "",
                                        modelo: "",
                                        cidade: "",
                                        profissao: "",
                                        cnpj: "",
                                        ramoEmpresa: ""
                                    })

                                        const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;
                
                                        if (modal) {
                                          modal.close();
                                          setEtapa(0);
                                        } else {
                                          console.error("Element with ID 'modal_proposta' not found.");
                                        }
                                }} className="btn btn-primary text-white">Enviar Proposta</button>
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    )
}